import React, { useEffect, useState }from 'react'
import BlogCard from './cards/BlogCard'
import ThreeDivLine from './ThreeDivLine'
import { NavLink } from 'react-router-dom'
import { fetchCardData } from '../api/blogsService'
import Loader from './NotFound/Loader';

function BlogsContainer({title}) {
    const [cards, setCards] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getCards = async () => {
          try {
            const data = await fetchCardData(0,3);
            setCards(data); // Assuming you want to display first 4 cards
          } catch (err) {
            setError('Failed to load card data.');
          } finally {
            setLoading(false);
          }
        };
    
        getCards();
      }, [count]);
    return (
        <section className="gs__blogs-section py-3 py-md-5">
            <div className='container gs__blogs-container'>
                <div className="row justify-content-center pb-4">
                    <div className="col-12 col-md-12 d-flex">
                        <div className="blog-title-container">
                            <h2 className='blog-title'>{title}</h2>
                            <div className="line-container">
                                <ThreeDivLine backgroundColor='#34AD27A3' />
                            </div>
                        </div>
                        <div className="btn-container ms-auto d-flex align-items-center">
                            <NavLink to={"/blogs"}>See More</NavLink>
                          
                        </div>
                    </div>
                </div>
                <div className="row  justify-content-center pb-0 pb-md-5 ">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-12 gs__blog-card-container">
                            {cards && cards.length > 0 ? (cards.map(card => (
                                <BlogCard key={card.id} card={card} />
                                    ))) : (<>Loader.....</>)}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogsContainer