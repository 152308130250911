import React from 'react'

import { ABOUT_US_CARDS } from '../../../constants/AboutUsConstant'

function AboutUsCards() {
    return (
        <section className="core-vision-cards py-5">
            <div className="container">
                <div className="cards-container">
                    {ABOUT_US_CARDS.map((card, index) => (
                        <div className="card" key={index}>
                            <div className="card-body">
                                <div className="card-icon">
                                    <img src={card.icon} alt="" />
                                </div>
                                <h5 className="card-title">{card.title}</h5>
                                <p className="card-text">{card.content}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default AboutUsCards