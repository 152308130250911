import React from "react";
import './ContactUsCard.css';



function ContactUsCard({ phone, email, address }){
    return(
        <div className="contact-us-card">
    <h3>Contact Us</h3>
    <div className="contact-detail">
      <strong>Phone:</strong> <span>{phone}</span>
    </div>
    <div className="contact-detail">
      <strong>Email:</strong> <span>{email}</span>
    </div>
    <div className="contact-detail">
      <strong>Address:</strong> <span>{address}</span>
    </div>
  </div>
    )
    
}
export default ContactUsCard;
