import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import oneTimeImage from '../../assets/icons/calendar-date-one.svg';
import monthTimeImage from '../../assets/icons/calendar-date-month.svg';
import axios from 'axios';
import {baseDomianURL} from '../../constants/config';

const PaymentForm = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('oneTime');
    const [inputType, setInputType] = useState('text');
    const [formValues, setFormValues] = useState({
        currency: 'Indian Rupee (INR)',
        amount: '',
        email: '',
        name: '',
        phone: '',
        pan: '',
        country: 'India',
        state: 'Assam',
        houseNo: '',
        pincode: '',
        city: '',
        streetName: '',
        event: '',
        eventType: 'Donation',
        eventDate: '',
        remarks: '',
        updates: false
    });
    const [errors, setErrors] = useState({});

    const validateInput = (name, value) => {
        let error = "";
      
        switch (name) {
          
          case "email":
            error = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? "" : "Invalid email address";
            break;
          case "phone":
            error = /^[0-9]{10}$/.test(value) ? "" : "Phone number must be 10 digits";
            break;
          case "pan":
            error = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value) ? "" : "Invalid PAN format";
            break;
          case "amount":
            error = /^[1-9]\d*$/.test(value) ? "" : "Invalid Amount";
            break;
          case "pincode":
            error = /^[1-9][0-9]{5}$/.test(value) ? "" : "Invalid pincode";
            break;
          case "houseNo":
          case "city":
          case "streetName":
            error = value ? "" : "This field is required";
            break;
          default:
            break;
        }
      
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
      };

    const handleInputChange = (e) => {
        let { id, value, type, checked } = e.target;
        value = id === 'pan' ? value.toUpperCase() : value;
        validateInput(id, value);
        setFormValues({
            ...formValues,
            [id]: type === 'checkbox' ? checked : value
        });
    };

    const validate = () => {
        const newErrors = {};
        const validationRules = {
            amount: {
                required: true,
                pattern: /^[1-9]\d*$/,
                errorMessage: 'Enter a valid amount',
            },
            email: {
                required: true,
                pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                errorMessage: 'Enter a valid email address',
            },
            name: {
                required: true,
                errorMessage: 'Name is required',
            },
            phone: {
                required: true,
                pattern: /^[6-9]\d{9}$/,
                errorMessage: 'Enter a valid 10-digit phone number',
            },
            pan: {
                required: true,
                pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                errorMessage: 'Enter a valid PAN number',
            },
            houseNo: {
                required: true,
                errorMessage: 'House number is required',
            },
            pincode: {
                required: true,
                pattern: /^\d{6}$/,
                errorMessage: 'Enter a valid 6-digit pincode',
            },
            city: {
                required: true,
                errorMessage: 'City is required',
            },
            streetName: {
                required: true,
                errorMessage: 'Street name is required',
            },
            event: {
                required: true,
                errorMessage: 'Event is required',
            },
            eventDate: {
                required: true,
                errorMessage: 'Event date is required',
            },
        };
        // Loop through the validation rules
    Object.keys(validationRules).forEach((field) => {
        const { required, pattern, validate, errorMessage } = validationRules[field];
        const value = formValues[field];

        // Check if the field is required and missing
        if (required && !value) {
            newErrors[field] = `${field.charAt(0).toLowerCase() + field.slice(1)} is required`;
        } 
        // If a pattern is defined, check if the value matches the pattern
        else if (pattern && !pattern.test(value)) {
            newErrors[field] = errorMessage;
        }
        // If a custom validation function is provided, use it
        else if (validate && !validate(value)) {
            newErrors[field] = errorMessage;
        }
    });

    return newErrors;
    };
    const handleFocus = (e) => {
        setInputType('date'); // Switch to 'date' on focus
      };
    
      const handleBlur = (e) => {
        if (!e.target.value) {
          setInputType('text');
        }
      };
    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length === 0) {
            
            handlePayment(formValues);
        } else {
            setErrors(validationErrors);
        }
    };

    const handlePayment = async (formValues) => {
       let address = `${formValues.houseNo} ${formValues.streetName} ${formValues.city} ${formValues.state}, ${formValues.pincode}`
        try {
        const orderUrl = baseDomianURL+'/api/payment/create';
        const { data } = await axios.post(orderUrl,  formValues );
       
        const options = {
            key: 'rzp_live_R8O066d84xirdS', // Your API key
            amount: data.amount,
            currency: data.currency,
            name: formValues.name,
            description: formValues.eventType,
            order_id: data.id,
            handler: async function (response) {
                const verifyUrl = baseDomianURL+'/api/payment/verify';
                const verificationResponse = await axios.post(verifyUrl, {
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_signature: response.razorpay_signature,
                });
                console.debug(verificationResponse);
                navigate(`/payment/payment_status?orderId=${data.id}`);     
            },
            prefill: {
                name: formValues.name,
                email: formValues.email,
                contact: formValues.phone,
            },
            notes: {
                address: address,
            },
            theme: {
                color: '#3399cc',
            },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    } catch (error) {
        console.log(error); 
     }
    };

    return (
        <div className="container gs__payment-form mt-5 py-5">
            <ul className="nav nav-tabs">
                <li className="nav-item w-50">
                    <a
                        className={`nav-link d-flex align-items-center payment-duration-link justify-content-center one-time-payment ${activeTab === 'oneTime' ? 'active' : ''}`}
                        onClick={() => setActiveTab('oneTime')}
                        href="#"
                    >
                        <img src={oneTimeImage} alt="one-time-payment-icon" />
                        One Time Payment
                    </a>
                </li>
                <li className="nav-item w-50">
                    <a
                        className={`nav-link d-flex align-items-center payment-duration-link justify-content-center monthly-time-payment ${activeTab === 'monthly' ? 'active' : ''}`}
                        onClick={() => setActiveTab('monthly')}
                        href="#"
                    >
                        <img src={monthTimeImage} alt="month-time-payment-icon" />
                        Monthly Payment
                    </a>
                </li>
            </ul>

            {activeTab === 'oneTime' && (
                <form onSubmit={handleSubmit}>
                    <fieldset className="p-3 mb-4">
                        <legend className="px-2">Choose Currency & Amount</legend>
                        <div className="form-group row">
                            <div className="col-sm-9 col-md-6">
                                <select className="form-control" id="currency" value={formValues.currency} onChange={handleInputChange}>
                                    <option>Indian Rupee (INR)</option>
                                </select>
                            </div>
                            <div className="col-sm-9 col-md-6 input-field-container">
                                <input type="number" className="form-control input-field" id="amount" placeholder="Enter Amount" value={formValues.amount} onChange={handleInputChange} />
                                <label className='input-field-label' htmlFor="amount">Enter Amount <span className='red-star'>*</span></label>
                                {errors.amount && <div className="text-danger">{errors.amount}</div>}
                                
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="p-3 mb-4">
                        <legend className="px-2">Personal Details</legend>
                        <div className="form-group row">
                            <div className="col-sm-9 col-md-6">
                                <div className="input-field-container">
                                <input type="email" className="form-control input-field" id="email" placeholder="Email Address" value={formValues.email} onChange={handleInputChange} />
                                <label className='input-field-label' htmlFor="email">Email Address <span className='red-star'>*</span></label>
                                {errors.email && <div className="text-danger">{errors.email}</div>}
                                </div>
                                <div className="input-field-container">
                                <input type="text" className="form-control input-field" id="name" placeholder="Full Name" value={formValues.name} onChange={handleInputChange} />
                                <label className='input-field-label' htmlFor="name">Full Name <span className='red-star'>*</span></label>
                                {errors.name && <div className="text-danger">{errors.name}</div>}
                                </div>
                            </div>
                            <div className="col-sm-9 col-md-6">
                                <div className="input-field-container">
                                <input type="tel" className="form-control input-field" id="phone" placeholder="Phone Number" value={formValues.phone} onChange={handleInputChange} />
                                <label className='input-field-label' htmlFor="phone">Phone Number <span className='red-star'>*</span></label>
                                {errors.phone && <div className="text-danger">{errors.phone}</div>}
                                </div>
                                <div className="input-field-container">
                                <input type="text" className="form-control input-field" id="pan" placeholder="PAN Number" value={formValues.pan} onChange={handleInputChange} />
                                <label className='input-field-label' htmlFor="pan">PAN Number<span className='red-star'>*</span></label>
                                {errors.pan && <div className="text-danger">{errors.pan}</div>}
                                </div> 
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="p-3 mb-4">
                        <legend className="px-2">Address</legend>
                        <div className="form-group row">
                            <div className="col-sm-9 col-md-6">
                                <select className="form-control" id="country" value={formValues.country} onChange={handleInputChange}>
                                    <option>India</option>
                                </select>
                                <select className="form-control" id="state" value={formValues.state} onChange={handleInputChange}>
                                    <option value="">Select State</option>
                                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                    <option value="Assam">Assam</option>
                                    <option value="Bihar">Bihar</option>
                                    <option value="Chhattisgarh">Chhattisgarh</option>
                                    <option value="Goa">Goa</option>
                                    <option value="Gujarat">Gujarat</option>
                                    <option value="Haryana">Haryana</option>
                                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                                    <option value="Jharkhand">Jharkhand</option>
                                    <option value="Karnataka">Karnataka</option>
                                    <option value="Kerala">Kerala</option>
                                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                                    <option value="Maharashtra">Maharashtra</option>
                                    <option value="Manipur">Manipur</option>
                                    <option value="Meghalaya">Meghalaya</option>
                                    <option value="Mizoram">Mizoram</option>
                                    <option value="Nagaland">Nagaland</option>
                                    <option value="Odisha">Odisha</option>
                                    <option value="Punjab">Punjab</option>
                                    <option value="Rajasthan">Rajasthan</option>
                                    <option value="Sikkim">Sikkim</option>
                                    <option value="Tamil Nadu">Tamil Nadu</option>
                                    <option value="Telangana">Telangana</option>
                                    <option value="Tripura">Tripura</option>
                                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                                    <option value="Uttarakhand">Uttarakhand</option>
                                    <option value="West Bengal">West Bengal</option>
                                    <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                    <option value="Chandigarh">Chandigarh</option>
                                    <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
                                    <option value="Lakshadweep">Lakshadweep</option>
                                    <option value="Delhi">Delhi</option>
                                    <option value="Puducherry">Puducherry</option>
                                    <option value="Ladakh">Ladakh</option>
                                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                </select>

                                <input type="text" className="form-control" id="houseNo" placeholder="House no." value={formValues.houseNo} onChange={handleInputChange} />
                                {errors.houseNo && <div className="text-danger">{errors.houseNo}</div>}
                            </div>
                            <div className="col-sm-9 col-md-6">
                                <input type="text" className="form-control" id="pincode" placeholder="Pincode" value={formValues.pincode} onChange={handleInputChange} />
                                {errors.pincode && <div className="text-danger">{errors.pincode}</div>}
                                <input type="text" className="form-control" id="city" placeholder="City" value={formValues.city} onChange={handleInputChange} />
                                {errors.city && <div className="text-danger">{errors.city}</div>}
                                <input type="text" className="form-control" id="streetName" placeholder="Street Name" value={formValues.streetName} onChange={handleInputChange} />
                                {errors.streetName && <div className="text-danger">{errors.streetName}</div>}
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="p-3 mb-4">
                        <legend className="px-2">Event Date & Type</legend>
                        <div className="form-group row">
                            <div className="col-sm-9 col-md-6">
                                <div className="input-field-container">
                                <input type="text" className="form-control input-field" id="event" placeholder="Event for" value={formValues.event} onChange={handleInputChange} />
                                <label className='input-field-label' htmlFor="event">Event for<span className='red-star'>*</span></label>
                                {errors.event && <div className="text-danger">{errors.event}</div>}
                                </div>
                                <select className="form-control" id="eventType" value={formValues.eventType} onChange={handleInputChange}>
                                    <option value="donation">Donation</option>
                                </select>
                            </div>
                            <div className="col-sm-9 col-md-6">
                                <div className="input-field-container">
                                <input type={inputType} className="form-control input-field" id="eventDate"  placeholder="Date" onFocus={handleFocus}
                                            onBlur={handleBlur}   value={formValues.eventDate} onChange={handleInputChange} />
                                      <label className='input-field-label' htmlFor="event">Event Date<span className='red-star'>*</span></label>
                                {errors.eventDate && <div className="text-danger">{errors.eventDate}</div>}
                                </div>
                               
                                <input type="text" className="form-control" id="remarks" placeholder="Remarks" value={formValues.remarks} onChange={handleInputChange} />
                            </div>
                        </div>
                    </fieldset>
                    <div className="form-group mb-3">
                        <div className="form-check d-flex justify-content-center align-items-center gap-2">
                            <input className="form-check-input input-field-checkbox" type="checkbox" id="updates" checked={formValues.updates} onChange={handleInputChange} />
                            <label className="form-check-label" htmlFor="updates">
                                Receive updates and reminders through SMS, WhatsApp or Emails
                            </label>
                        </div>
                    </div>
                    <div className="btn-container d-flex justify-content-center">
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>
            )}

            {activeTab === 'monthly' && (
                <form onSubmit={handleSubmit}>
                <fieldset className="p-3 mb-4">
                    <legend className="px-2">Choose Currency & Amount</legend>
                    <div className="form-group row">
                        <div className="col-sm-9 col-md-6">
                            <select className="form-control" id="currency" value={formValues.currency} onChange={handleInputChange}>
                                <option>Indian Rupee (INR)</option>
                            </select>
                        </div>
                        <div className="col-sm-9 col-md-6 input-field-container">
                            <input type="number" className="form-control input-field" id="amount" placeholder="Enter Amount" value={formValues.amount} onChange={handleInputChange} />
                            <label className='input-field-label' htmlFor="amount">Enter Amount <span className='red-star'>*</span></label>
                            {errors.amount && <div className="text-danger">{errors.amount}</div>}
                            
                        </div>
                    </div>
                </fieldset>
                <fieldset className="p-3 mb-4">
                    <legend className="px-2">Personal Details</legend>
                    <div className="form-group row">
                        <div className="col-sm-9 col-md-6">
                            <div className="input-field-container">
                            <input type="email" className="form-control input-field" id="email" placeholder="Email Address" value={formValues.email} onChange={handleInputChange} />
                            <label className='input-field-label' htmlFor="email">Email Address <span className='red-star'>*</span></label>
                            {errors.email && <div className="text-danger">{errors.email}</div>}
                            </div>
                            <div className="input-field-container">
                            <input type="text" className="form-control input-field" id="name" placeholder="Full Name" value={formValues.name} onChange={handleInputChange} />
                            <label className='input-field-label' htmlFor="name">Full Name <span className='red-star'>*</span></label>
                            {errors.name && <div className="text-danger">{errors.name}</div>}
                            </div>
                        
                            

                        </div>
                        <div className="col-sm-9 col-md-6">
                            <div className="input-field-container">
                            <input type="tel" className="form-control input-field" id="phone" placeholder="Phone Number" value={formValues.phone} onChange={handleInputChange} />
                            <label className='input-field-label' htmlFor="phone">Phone Number <span className='red-star'>*</span></label>
                            {errors.phone && <div className="text-danger">{errors.phone}</div>}
                            </div>
                            <div className="input-field-container">
                            <input type="text" className="form-control input-field" id="pan" placeholder="PAN Number" value={formValues.pan} onChange={handleInputChange} />
                            <label className='input-field-label' htmlFor="pan">PAN Number<span className='red-star'>*</span></label>
                            {errors.pan && <div className="text-danger">{errors.pan}</div>}
                            </div>
                          
                           
                        </div>
                    </div>
                </fieldset>
                <fieldset className="p-3 mb-4">
                    <legend className="px-2">Address</legend>
                    <div className="form-group row">
                        <div className="col-sm-9 col-md-6">
                            <select className="form-control" id="country" value={formValues.country} onChange={handleInputChange}>
                                <option>India</option>
                            </select>
                            <select className="form-control" id="state" value={formValues.state} onChange={handleInputChange}>
                                <option>Faridabad</option>
                            </select>
                            <input type="text" className="form-control" id="houseNo" placeholder="House no." value={formValues.houseNo} onChange={handleInputChange} />
                            {errors.houseNo && <div className="text-danger">{errors.houseNo}</div>}
                        </div>
                        <div className="col-sm-9 col-md-6">
                            <input type="text" className="form-control" id="pincode" placeholder="Pincode" value={formValues.pincode} onChange={handleInputChange} />
                            {errors.pincode && <div className="text-danger">{errors.pincode}</div>}
                            <input type="text" className="form-control" id="city" placeholder="City" value={formValues.city} onChange={handleInputChange} />
                            {errors.city && <div className="text-danger">{errors.city}</div>}
                            <input type="text" className="form-control" id="streetName" placeholder="Street Name" value={formValues.streetName} onChange={handleInputChange} />
                            {errors.streetName && <div className="text-danger">{errors.streetName}</div>}
                        </div>
                    </div>
                </fieldset>
                <fieldset className="p-3 mb-4">
                    <legend className="px-2">Event Date & Type</legend>
                    <div className="form-group row">
                        <div className="col-sm-9 col-md-6">
                            <div className="input-field-container">
                            <input type="text" className="form-control input-field" id="event" placeholder="Event for" value={formValues.event} onChange={handleInputChange} />
                            <label className='input-field-label' htmlFor="event">Event for<span className='red-star'>*</span></label>
                            {errors.event && <div className="text-danger">{errors.event}</div>}
                            </div>
                            <select className="form-control" id="eventType" value={formValues.eventType} onChange={handleInputChange}>
                                <option value="donation">Donation</option>
                            </select>
                        </div>
                        <div className="col-sm-9 col-md-6">
                            <div className="input-field-container">
                            <input type={inputType} className="form-control input-field" id="eventDate"  placeholder="Date" onFocus={handleFocus}
                                        onBlur={handleBlur}   value={formValues.eventDate} onChange={handleInputChange} />
                                  <label className='input-field-label' htmlFor="event">Event Date<span className='red-star'>*</span></label>
                            {errors.eventDate && <div className="text-danger">{errors.eventDate}</div>}
                            </div>
                           
                            <input type="text" className="form-control" id="remarks" placeholder="Remarks" value={formValues.remarks} onChange={handleInputChange} />
                        </div>
                    </div>
                </fieldset>
                <div className="form-group mb-3">
                    <div className="form-check d-flex justify-content-center align-items-center gap-2">
                        <input className="form-check-input input-field-checkbox" type="checkbox" id="updates" checked={formValues.updates} onChange={handleInputChange} />
                        <label className="form-check-label" htmlFor="updates">
                            Receive updates and reminders through SMS, WhatsApp or Emails
                        </label>
                    </div>
                </div>
                <div className="btn-container d-flex justify-content-center">
                    <button type="submit" className="btn btn-primary">Submit</button>
                </div>
            </form>
            )}
        </div>
    );
};

export default PaymentForm;
