import React, { useEffect, useState } from 'react';
import { useLocation} from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import axios from 'axios';
import {baseDomianURL} from '../../constants/config';

function DonationReceipt() {
    const [paymentData, setPaymentData] = useState(null);
    const [status, setStatus] = useState('loading'); // Initial status as loading
    const location = useLocation();
  
    const queryParams = new URLSearchParams(location.search);
    const orderId = queryParams.get('orderId');

    const handleDownloadPDF = () => {
        html2canvas(document.querySelector('.receipt-container')).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'PNG', 0, 0);
            pdf.save('receipt.pdf');
        });
    };
    useEffect(() => {
        if (orderId) {
          const fetchPaymentStatus = async () => {
            try {
              const verifyUrl = `${baseDomianURL}/api/payment/status/${orderId}`;
              const data = await axios.get(verifyUrl);
              setPaymentData(data.data.data);
              setStatus(data.data.status); // Assuming `data.status` contains the payment status
            } catch (error) {
              console.error("Error fetching payment status:", error);
              setStatus('error');
            }
          };
    
          fetchPaymentStatus();
        } else {
          setStatus('error'); // Handle case when orderId is not present
        }
      }, [orderId]);
    return (
        
        <div className="receipt-container">
             {paymentData && (
                <>
            <div className="receipt-header">
                <p>ॐ श्रीपरमात्मने नमः</p>
                <p>ॐ जय श्रीराम</p>
                <p>ॐ जय श्रीगौमाता</p>
            </div>
            <div className="receipt-title">
                <h2>SHREE GEETA GAUSHALA PARAMARTH SANSTHA (Trust)</h2>
                <p>PAN No: AAKTS999J | AWBI Reg. No: ASO152/2019</p>
                <p>A.T Road, Padumani, Jorhat 785010 (Assam)</p>
                <p>Mobile: 9435090285, 7002959522</p>
            </div>
            <div className="receipt-body">
                <p>Receipt No: <span className="receipt-no">15514</span></p>
                <p>Received with thanks from Sri/Smt/Ms: <span className="donor-name">{paymentData.name}</span></p>
                <p>Towards Corpus Fund/General Fund of the Trust, Gau Raksha/Patipalan, Life Time/Half Yearly/Yearly Tuladan:</p>
                <p className="donation-purpose">Sawamani, Fodder & Cattle Feed, Mangal Kalash No. Gaudan, Medicine or Miscellaneous:</p>
                <p>Rs: <span className="donation-amount">{(paymentData.amount / 100).toFixed(2)}</span> only.</p>
                <p>Mob No: <span className="mode-no">{paymentData.phone}</span> | Date: <span className="date">{new Date(paymentData.eventDate).toLocaleDateString()}</span></p>
                <p>By Cash/Cheque/RTGS/NEFT/GPAY</p>
                <p>PAN No: <span className="pan-no">{paymentData.pan}</span></p>
            </div>
            <div className="receipt-footer">
                <p>For Shree Geeta Gaushala Paramarth Sanstha</p>
                <p>Authorised Signatory</p>
            </div>
            <div className="receipt-actions">
                <button onClick={handleDownloadPDF}>Download PDF</button>
            </div>
            </>
             )}
        </div>
    )
}

export default DonationReceipt