import React from 'react'
import { NavLink } from 'react-router-dom'
import NewsLetter from '../NewsLetter'

function Footer() {
    return (
        <>
            <div className=" container-fluid gs__footer bottom-0 right-0 left-0 ">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className="contact__wrapper">
                            <div className="about__section">
                                <h2 className='about__title'>Contact Us</h2>
                                {/* <p className='about__description'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam.</p> */}
                            </div>
                            <div className="contact__section">
                                <div className="contact__phone"><span className="text-white">Email : </span><span className="careshreegeetagaushalacom">care@shreegeetagaushala.com</span>
                                </div><div className="contact__email email"><span className="text-white">Phone : </span><span className="careshreegeetagaushalacom">+91 8510089892</span></div>
                            </div>
                        </div>

                    </div>
                    <div className="col-12 col-md-4 gs__footer-links-container">
                        <div className="d-flex flex-row justify-content-between">
                            <div className='d-flex flex-column'>
                                <h2 className='bottom-heading mb-4'>Quick Link</h2>
                                <ul className='footer-navigation-list'>
                                    <li className='mb-2'> <NavLink className="footer-nav-link text-decoration-none" aria-current="page" to="/">Home</NavLink></li>
                                    {/* <li className='mb-2'><NavLink className="footer-nav-link text-decoration-none" to="/about-us/vision" >About Us</NavLink></li> */}
                                    <li className='mb-2'> <NavLink className="footer-nav-link text-decoration-none" to="/blogs">Blog</NavLink></li>
                                    <li className='mb-2'><NavLink className="footer-nav-link text-decoration-none" to="/our-mission">Missions</NavLink></li>
                                    <li className='mb-2'><NavLink className="footer-nav-link text-decoration-none" to="/policies/tnc" >Term & Conditions</NavLink></li>
                                    <li className='mb-2'><NavLink className="footer-nav-link text-decoration-none" to="/policies/user-agreement" >User Agreement</NavLink></li>
                                    <li className='mb-2'><NavLink className="footer-nav-link text-decoration-none" to="/policies/refund-policy" >Refund Policy</NavLink></li>
                                </ul>
                            </div>
                            <div className='d-flex flex-column'>
                                <h2 className='bottom-heading mb-4'>
                                    Activities
                                </h2>
                                <ul className='footer-navigation-list'>
                                    <li className='mb-2'><NavLink className="footer-nav-link  text-decoration-none" to="#gauraksha">Gauraksha</NavLink></li>
                                    <li className='mb-2'><NavLink className="footer-nav-link  text-decoration-none" to="#medical__treatment">Medical treatement</NavLink></li>
                                    <li className='mb-2'> <NavLink className="footer-nav-link   text-decoration-none" to="#food__shelter">Food & Shelter</NavLink></li>
                                    <li className='mb-2'><NavLink className="footer-nav-link   text-decoration-none" to="#yagya__cultural__vents" > Yagya & Cultural Events</NavLink></li>
                                    <li className='mb-2'><NavLink className="footer-nav-link  text-decoration-none" to="#guraksha__ambulance" >Gauraksha Ambulance</NavLink></li>
                                    <li className='mb-2'><NavLink className="footer-nav-link   text-decoration-none" to="#homeschool" >HomeSchool</NavLink></li>

                                </ul>
                            </div>

                        </div>

                    </div>
                    <div className="col-12 col-md-4">
                        <NewsLetter />
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-12 col-md-5 footer__details-container">
                        <div className="footer-web-details">
                            <h1 className='details-title'>Shree Geeta Gaushala Paramarth Sanstha</h1>
                            <p className='detail-details'>&copy; Shree Geeta Gaushala Paramarth Sanstha 2024. All Rights Reserved.</p>
                        </div>
                    </div>

                    <div className="col-12 col-md-7 footer__condition-container d-flex justify-content-md-end">
                        {/* <a href=""> Terms of Use</a>
                        <a href="">Privacy Policy</a>
                        <a href="">Cookie Policy</a> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer