import React from 'react'
import ContactUsForm from '../../components/forms/ContactUsForm'
import ContactUsBanner from '../../components/about-us-page/banner/ContactUsBanner/ContactUsBanner'
import ContactUsCardContainer from '../../components/cards/ContactUsCard/ContactUsCardContainer'

function Contact() {
  return (
    <>
    <ContactUsBanner title={"Contact Us"}/>
    <ContactUsForm/>
    <ContactUsCardContainer/>
    </>
  )
}

export default Contact