import React from 'react'

function GrassBanner() {
    return (
        <div className='container-fluid px-0'>
            <div className="gs__grass-wrapper">
                <div className="grb__content d-flex flex-column pt-5 pb-4 align-items-center">
                    <div className="grb__title mb-2">
                        <h1 className='text-white heading'>Gau Grass Sewa</h1>
                    </div>
                    <div className="grb__description mb-3">
                        <p className="text-white description" >you will not only be contributing to the well being of the cows but also paticipating in an  old
                            tradition that upholds the values of Sanatan Dharam</p>
                    </div>
                    <div className="grb__btn">
                        <button className='btn bg-transparent text-white border   border-white rounded-0 px-4 py-2' >Gain Spritual Merit</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default GrassBanner