import React, { useState } from 'react';
import AccordionItem from './AccordionItem';

const FaqAccordion = ({ data }) => {
    const [openIndex, setOpenIndex] = useState(null);

    const handleClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="accordion" id="faqAccordion">
            {data.map((item, index) => (
                <AccordionItem
                    key={index}
                    question={item.question}
                    answer={item.answer}
                    isOpen={openIndex === index}
                    onClick={() => handleClick(index)}
                    index={index}
                />
            ))}
        </div>
    );
};

export default FaqAccordion;
