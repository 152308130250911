import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import HeaderStrip from "../HeaderStrip";
import headerLogo from "../../assets/gsHeaderLogo.png";
import DonateLogo from "../../assets/icons/donateIcon.png";
import LoginModel from "../modal/LoginModel";
import HeaderQuote from "../HeaderQuote";
import './Header.css'
import { useState, useEffect } from "react";

function Header() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();
  // Toggle dropdown function
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    setIsDropdownOpen(false);
  }, [location]);
  return (
    <>
      <HeaderStrip />
      <div className="container-fluid gs_header">
        <div className="row">
          <div className="col-12 mx-auto">
            <nav className="navbar navbar-expand-lg bg-body-tertiary p-0">
              <div className="container-fluid">
                <NavLink className="navbar-brand" to="/">
                  <img src={headerLogo} alt="gaushala" />
                </NavLink>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNavAltMarkup"
                  aria-controls="navbarNavAltMarkup"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div className="btn-container class-p1 d-lg-none d-flex align-items-center gap-1">
                  <LoginModel />
                  <NavLink
                    className="donate-btn-top text-decoration-none"
                    to="/payment/payment_form"
                  >
                    Donate
                  </NavLink>
                </div>

                <div
                  className="collapse navbar-collapse"
                  id="navbarNavAltMarkup"
                >
                  <div className="navbar-nav mx-md-auto gap-md-3">
                    <NavLink className="nav-link" aria-current="page" to="/">
                      Home
                    </NavLink>
                    <NavLink className="nav-link" to="/our-activities">
                      Our Activities
                    </NavLink>
                    <NavLink className="nav-link" to="/our-mission">
                      Our Mission
                    </NavLink>

                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded={isDropdownOpen ? "true" : "false"}
                        onClick={toggleDropdown}
                      >
                        About Us
                      </a>
                      <ul
                        aria-labelledby="navbarDropdown"
                        className={`dropdown-menu ${
                          isDropdownOpen ? "show" : ""
                        }`}
                      >
                        <li>
                          <NavLink
                            className="dropdown-item"
                            to="/about-us/vision"
                          >
                            Vision and Mission
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className="dropdown-item"
                            to="/about-us/history"
                          >
                            History
                          </NavLink>
                        </li>
                      </ul>
                    </li>

                    <NavLink className="nav-link" to="/contact">
                      Contact
                    </NavLink>
                  </div>
                </div>
                <div className="btn-container class-p2 d-none d-lg-flex align-items-center gap-1">
                  <LoginModel />
                  <NavLink
                    className="donate-btn-top text-decoration-none"
                    to="/payment/payment_form"
                  >
                    Donate
                  </NavLink>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <HeaderQuote />
    </>
  );
}

export default Header;
