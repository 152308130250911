import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './AccediationsCarousel.css'
import RecognitionCard from '../cards/RecognitionCard';
import { ACCEDITATIONS } from '../../constants/Acceditations';
import ThreeDivLine from '../ThreeDivLine';
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 1024 },
        items: 4,
        slidesToSlide: 1 // optional, default to 1.
    },
    desktop: {
        breakpoint: { max: 1024, min: 768 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 768, min: 464 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

function AccrediationsCarousel() {
    return (
        <section className="gs__accederation py-5">
            <div className='container gs__acceredations-caroulel'>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-12 d-flex flex-row">
                        <div className="content-container">
                            <div className="heading-container">
                                <h2 className='title'>Accreditations & Recognitions</h2>
                            </div>
                            <div className="line-container">
                                <ThreeDivLine backgroundColor='#34AD27A3' />
                            </div>
                        </div>
                        <div className="btn-container ms-auto">
                            <a href="#" className="btn-more">More</a>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-12 py-5">
                        <Carousel
                            responsive={responsive}
                            swipeable={true}
                            draggable={true}
                            showDots={false}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={3000}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            renderDotsOutside={true}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            deviceType={"desktop"}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px">
                            {
                                ACCEDITATIONS.map((properties, index) => (

                                    <RecognitionCard key={index} compImg={properties.image} compImgAlt={properties.alt} description={properties.description} awardName={properties.award} />

                                ))
                            }

                        </Carousel>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AccrediationsCarousel;