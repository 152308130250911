import React from 'react'
import ActivitesCard from './cards/ActivitesCard'
import ThreeDivLine from './ThreeDivLine'

function ActivityContainer() {
    return (
        <section className="activity-container py-5">
            <div className='container gs_activity-container '>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-12">
                        <div className="row">
                            <div className="col-12  d-flex flex-row">
                                <div className="main-haeding-container">
                                    <div className="heading-container">
                                        <h2 className='gd_activity-heading'>Our Activities(Sewa)</h2>
                                    </div>
                                    <div className="line-container">
                                        <ThreeDivLine backgroundColor='#34AD27A3' />
                                    </div>
                                </div>
                                <div className="ms-auto btn-container">
                                    <a href="#">SeeMore </a>
                                </div>
                            </div>

                        </div>
                        <div className="row activity-container-heading py-4">
                            <div className="col-12">
                                <p className='container-heading'>The Geeta Ashrams is a place wherein these particular activities take place. Take a quick tour through the below section.</p>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-12 col-md-12 gs__activity-card-container">
                                <ActivitesCard />
                                <ActivitesCard />
                                <ActivitesCard />
                                <ActivitesCard />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ActivityContainer