import React from "react";
import TextWithViewMore from "../TextWithViewMore";

const ActivitiesCardItem=({ title, description, image })=>{
return(
    <div className="activity-card d-flex flex-column flex-md-row gap-4">
        <div className="card-img-container">
        <img src={image} alt={title} className="activity-image" />
        </div>
        <div className="content-container d-flex flex-column">
        <h3 className="activity-title">{title}</h3>
        <p className="activity-description"><TextWithViewMore text={description} limit={190}/></p>
        </div>
     
    </div>
)
}
export default ActivitiesCardItem;