import React, { useState } from 'react';
import './ContactUsForm.css'
function ContactUsForm(){
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    
      const [errors, setErrors] = useState({});
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const validate = () => {
        let tempErrors = {};
        let isValid = true;
    
        if (formData.name.trim() === "") {
          tempErrors.name = "Name is required.";
          isValid = false;
        }
    
        if (formData.email.trim() === "") {
          tempErrors.email = "Email is required.";
          isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          tempErrors.email = "Email is invalid.";
          isValid = false;
        }
    
        if (formData.message.trim() === "") {
          tempErrors.message = "Message is required.";
          isValid = false;
        }
    
        setErrors(tempErrors);
        return isValid;
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
    
        if (validate()) {
          console.log("Form Data:", formData);
          alert("Form submitted successfully!");
    
          // Reset form fields
          setFormData({
            name: '',
            email: '',
            subject: '',
            message: ''
          });
        }
      };
    
      return (
        <div className="contact-us-form container gs__contact-us-form my-3">
          <div className="row">
            <div className="col-md-6">
            <h2 className='form-title'>Contact Us</h2>
            <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
              />
              {errors.name && <span className="error">{errors.name}</span>}
            </div>
    
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
    
            <div className="form-group">
              <label htmlFor="subject">Subject:</label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder="Enter subject"
              />
            </div>
    
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Enter your message"
                rows="5"
              ></textarea>
              {errors.message && <span className="error">{errors.message}</span>}
            </div>
    
            <button type="submit" className='submit-btn'>Submit</button>
          </form>
            </div>
            <div className="col-md-6"></div>
          </div>
          
         
        </div>
      );
    }
    

export default ContactUsForm;