import React from 'react';
import PropTypes from 'prop-types';

function ThreeDivLine({ backgroundColor }) {
    const fixedHeight = 4; // Adjust this value to set the fixed height of the divs
    const fixedWidth = 4; // Adjust this value to set the fixed width of the first two divs

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <div style={{ height: `${fixedHeight}px`, width: `${fixedWidth}px`, backgroundColor: '#FF8400' }}></div>
            <div style={{ height: `${fixedHeight}px`, width: `${fixedWidth}px`, backgroundColor: '#FF8400A3' }}></div>
            <div style={{ height: `${fixedHeight}px`, flex: 1, backgroundColor }}></div>
        </div>
    );
}

ThreeDivLine.propTypes = {
    backgroundColor: PropTypes.string.isRequired
};

export default ThreeDivLine;