import React from 'react'
import WhoWeAre from '../../components/about-us-page/banner/WhoWeAre';
import OurRoles from '../../components/about-us-page/banner/OurRoles';
import JourneyTillNow from '../../components/about-us-page/banner/JourneyTillNow';
import AccrediationsCarousel from '../../components/carousel/AccrediationsCarousel';
import CoreVision from '../../components/about-us-page/banner/CoreVision';
import OurMissionBanner from '../../components/about-us-page/banner/OurMissionBanner';
import AboutUsCards from '../../components/about-us-page/banner/AboutUsCards';
import AboutUsBanner from '../../components/about-us-page/banner/AboutUsBanner';
import coreVisionImg from '../../assets/Banner/core-vision-img.png'
function AboutUs() {
    return (
        <div className='about-us-page'>
            <WhoWeAre />
            <JourneyTillNow />
            <OurRoles />
            <AccrediationsCarousel />
           
        </div>
    )
}

export default AboutUs;