import React from 'react'
import GrassBanner from '../../components/banner/GrassBanner'
import ActivityBanner from '../../components/banner/ActivityBanner'
import DonateForm from '../../components/forms/DonateForm'
import Recognization from '../../components/Recognization'
import MainBanner from '../../components/banner/MainBanner'
// import BlogCard from '../../components/cards/BlogCard'
import ActivitesCard from '../../components/cards/ActivitesCard'
import LoginModel from '../../components/modal/LoginModel'
import MissionCards from '../../components/cards/MissionCards'
import Carousel from '../../components/carousel/Carousel'
import ActivityContainer from '../../components/ActivityContainer'
import FaqSection from '../../components/Faq/FaqSection'
import BlogsContainer from '../../components/BlogsContainer'
import TestimonialContainer from '../../components/TestimonialContainer'
import VideoBanner from '../../components/banner/VideoBanner'
import HeritageContainer from '../../components/HeritageContainer'
import AccrediationsCarousel from '../../components/carousel/AccrediationsCarousel'


function Home() {
  return (
    <>
      <Carousel />
      <MainBanner />
      <ActivityBanner />
      <ActivityContainer />
      <AccrediationsCarousel />
      <HeritageContainer />
      <GrassBanner />
      <TestimonialContainer />
      <VideoBanner />
      {/* <DonateForm /> */}
      {/* <Recognization /> */}
      {/* <ShreeGeetaHome></ShreeGeetaHome> */}
      {/* <BlogCard /> */}
      {/* <ActivitesCard />
      <MissionCards /> */}

      <BlogsContainer title={"Blogs"}/>
      <FaqSection />

    </>

  )
}

export default Home