import React from 'react'
import { assets } from '../../assets/asset'

function ActivitesCard() {
    return (
        <>
            <div className="card card__activities">
                <img src={assets.cardsImg.activityCardImg} className="card-img-top" alt="..." />
                <div className="card-body">
                    <h2 className='card-heading'>Gau Grash Seva</h2>
                    <p className="card-text">Under the program of Gau Grash Sewa, we collect edibles from touring the whole city. Therefore, we feed it to cows in our ashram. We aim to make people aware of this concept so that a huge number of people can participate. It includes stuff like flour, vegetable leaves, chapatis, and gains, etc.  </p>
                </div>

                <div className="card-body">
                    <button className='btn activity-read-more-btn mt-3'> Read More</button>
                </div>

            </div>
        </>
    )
}

export default ActivitesCard