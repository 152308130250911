import compIcon from '../assets/acceditations/compLogo.png';
import mpDepartment from '../assets/icons/mp-ayog.png';
import incomeTaxD from '../assets/icons/incometaxd.png';
import nitiayog from '../assets/icons/niti-ayog.png'

export const ACCEDITATIONS = [
    {
        image: compIcon,
        alt: "compIcon",
        award: "award",
        description: "description"
    },
    {
        image: mpDepartment,
        alt: "mp-department",
        award: "award",
        description: "description"
    }, {
        image: incomeTaxD,
        alt: "incomeTax",
        award: "award",
        description: "description"
    },
    {
        image: nitiayog,
        alt: "niti-ayog",
        award: "award",
        description: "description"
    },
    {
        image: compIcon,
        alt: "compIcon",
        award: "award",
        description: "description"
    }, {
        image: mpDepartment,
        alt: "mp-department",
        award: "award",
        description: "description"
    }
]