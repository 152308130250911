import React from 'react'

function HeaderQuote() {
    return (
        <div className="container-fluid header__quote">
            <div className='quotes-heading'><h1>ॐ जय श्री गॏमाताय शरणम् प्रपधेः |ॐ जय श्री गॏमाताय शरणम् प्रपधेः |ॐ जय श्री गॏमाताय शरणम् प्रपधेः |ॐ जय श्री गॏमाताय शरणम् प्रपधेः |</h1></div>
        </div>
    )
}

export default HeaderQuote