import React from 'react'
import FAQAccordion from './FaqAccordion';
import ThreeDivLine from '../ThreeDivLine';
const faqData = [
    { question: 'What is the significance of Gau Raksha in Hinduism?',
        answer:"In Hinduism, the concept of gau raksha holds ultimate religious and cultural importance. Cows are considered a holy animal & a symbol of motherhood that needs to be protected."
     },
    { question: 'What is the Motive of Geeta Ashram’s Gau Raksha Drive?',
        answer:"The primary motive of Geeta Ashram’s Gau Raksha Drive is to build the values of Sanatan dharma, spread the knowledge of Bhagavad Geeta, and make our country a world guru through the practices of cow protection."
     },
    { question: 'What Activities are involved in Geeta Gaushala’s Cow Protection Drive? ', 
        answer: 'In the Geeta Gaushala’s cow protection drive, there are other such activities also including cow awareness campaigns, providing medical care, and adoption of cows from our gaushala.' },
    { question: 'What is the need to protect cow breeds in gaushalas?', 
        answer: 'Cow protection shelters like Geeta Gaushala provide a haven for all cow breeds. It helps to focus on cow breed purities, care, and ultimate protection from slaughterhouses.' },
    { question: 'What are the major impacts of Gau Raksha on our society?',
         answer: 'Gau Raksha helps to build a religious connection in our society. It promotes cow welfare, focuses on non-violent methods, and also gives individuals another way of livelihood and economic stability.' },
    // Add more FAQs here
];



function FaqSection() {
    return (
        <section className="faq-section py-3 py-md-5">
            <div className="container">
                <div className="row justify-content-center pb-4">
                    <div className="col-12 col-md-12 d-flex">
                        <div className="faq-title-container">
                            <h2 className='faq-title'>FAQ</h2>
                            <div className="line-container">
                                <ThreeDivLine backgroundColor='#34AD27A3' />
                            </div>
                        </div>
                        <div className="btn-container ms-auto d-flex align-items-center">
                            <a href="#">See More</a>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-12">
                        < FAQAccordion data={faqData} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FaqSection;

