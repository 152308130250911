import React from 'react'
import blogCardImg from '../../assets/cards/blog_card_img.png'
import author from '../../assets/icons/author.png'
import { assets } from '../../assets/asset'

function BlogCard({ card  }) {
    const {
        link = '/blog/'+ card.id, // Default link if not provided
        title = 'Title',
        authorName = 'Anonymous',
        imageUrl, // Default blog image
    } = card;
    return (
        <>
        <a href={link}>
            <div className="card card__blogs">
                <img src={imageUrl || assets.cardsImg.blogCardImg} className="card-img-top blog-card-img" alt="..." />
                <div className="card-body">
                    <p className="card-text">{title}</p>
                </div>

                <div className="card-body card-body-bottom d-flex flex-row align-items-center">
                    <div className="img-container">
                        <img src={author} alt="author" width={36} height={36} />
                    </div>
                    <div className="name-container">
                        <p className='poster-name'>{authorName}</p>
                    </div>
                </div>

            </div>
        </a>
        </>
    )
}

export default BlogCard;