import React from "react";
import './RefundPolicy.css'
import ContactUsBanner from "../../components/about-us-page/banner/ContactUsBanner/ContactUsBanner";
function RefundPolicy(){
    return(
        <>
        <ContactUsBanner title={"Refund Policy"}/>
        <div className="refund-policy">
            <div className="head-description">
                <h2>
                Refunds Policy 
                </h2>
                <p>We do not provide a return, refund, or cancellation policy once the product has been ordered. All sales are considered final, and no cancellations will be accepted after the purchase.</p>
            </div>
            <div className="head-description">
                <h2>
                Quality Assurance

                </h2>
                <p>Shree Geeta gaushala makes every effort to provide the highest quality products and services. All models undergo rigorous quality checks and necessary tests to ensure efficiency, electrical safety, and reliability before they are dispatched.</p>
            </div>
            <div className="head-description">
                <h2>
                Important Information

                </h2>
                <p>Once an order is placed, no refunds or cancellations will be processed. Please ensure that your purchase is final and meets your requirements.</p>
            </div>
            <p>If you have any questions about our policy, please contact us by email at: <a href="mailto:care@shreegeetagaushala.org">care@shreegeetagaushala.org</a>.</p>
        </div>
        </>
    )
}

export default RefundPolicy;