import React from 'react'
import TestimonialCard from './cards/TestimonialCard'
import ThreeDivLine from './ThreeDivLine';
const testimonials = [
    {
        image: 'https://via.placeholder.com/100', // Replace with actual image URL
        name: 'John Doe',
        rating: 3,
        description: 'Cows provide us with milk, butter, cheese, fuel, and a cleanser, and more but require nothing in return. These animals do more for humans than any other animal to the people.'
    },
    {
        image: 'https://via.placeholder.com/100', // Replace with actual image URL
        name: 'Jane Smith',
        rating: 4,
        description: 'This is an amazing product! I would definitely recommend it to others.'
    },
    {
        image: 'https://via.placeholder.com/100', // Replace with actual image URL
        name: 'Alice Johnson',
        rating: 5,
        description: 'The best service I have ever used. Highly recommended!'
    },
    {
        image: 'https://via.placeholder.com/100', // Replace with actual image URL
        name: 'Bob Brown',
        rating: 2,
        description: 'Not satisfied with the product. It did not meet my expectations.'
    }
];
function TestimonialContainer() {
    return (
        <section className="gs__testimonial py-3 py-md-5">
            <div className='container gs__testimonal-container px-3'>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-12 d-flex">
                        <div className="testimonials-title-container">
                            <h2 className='testimonials-title'>Testimonials</h2>
                            <div className="line-container">
                                <ThreeDivLine backgroundColor='#34AD27A3' />
                            </div>
                        </div>
                        <div className="btn-container ms-auto d-flex align-items-center">
                            <a href="#">See More</a>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center py-5">
                    <div className="col-12 col-md-12 gs__testimonial-card-container">
                        {testimonials.map((testimonial, index) => (

                            <TestimonialCard key={index} testimonial={testimonial} />

                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TestimonialContainer