import React, { useState } from 'react';
import './LanguageDropdown.css'
import { assets } from '../assets/asset';
function LanguageDropdown() {
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('Select Language');

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleLanguageSelect = (language) => {
        setSelectedLanguage(language);
        setShowDropdown(false);
    };

    return (
        <div className="language-dropdown">
            <button onClick={toggleDropdown} className="dropdown-btn">
                <img src={assets.icons.outlineGlobal} alt="global" width={15} />
                {selectedLanguage}
            </button>
            {showDropdown && (
                <div className="dropdown-content">
                    <button onClick={() => handleLanguageSelect('English')}>English</button>
                    <button onClick={() => handleLanguageSelect('Hindi')}>Hindi</button>
                    {/* Add more languages as needed */}
                </div>
            )}
        </div>
    );
}

export default LanguageDropdown;
