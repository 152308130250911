import React from 'react'


const TestimonialCard = ({ testimonial }) => {
    return (
        <div className="card card__testimonal text-center p-3">
            <div className="d-flex justify-content-center">
                <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="rounded-circle card__testimonal-image"
                />
            </div>
            <div className="card-body">
                <h5 className="card-title mt-3">{testimonial.name}</h5>
                <div className="d-flex justify-content-center my-2">
                    {[...Array(5)].map((star, index) => (
                        <span
                            key={index}
                            className={`star ${index < testimonial.rating ? 'filled' : ''}`}
                        >&#9733;</span>
                    ))}
                </div>
                <p className="card-text">{testimonial.description}</p>
            </div>
        </div>
    );
};

export default TestimonialCard;