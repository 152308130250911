import React from 'react'
import cowImageBg from '../.././assets/Banner/topBannerCowBg.png';
import cowImg from '../.././assets/Banner/topCowBannerImage.png'
function MainBanner() {
    return (
        <>
            <section className="main-banner py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12">
                            <div className="container gs_main-banner px-0">
                                <div className="row justify-content-between">
                                    <div className="col-md-8">
                                        <div className="main-banner-content">
                                            <div className="main-banner-title">
                                                <h1 className='banner-title'>Shree Geeta Gaushala</h1>
                                            </div>
                                            <div className="main-banner-subtitle">
                                                <h2 className='banner-subtitle'>A Legacy Of Cow Protection & Care</h2>
                                            </div>
                                            <div className="main-banner-description">
                                                <p className="banner-description">Shree Geeta Gaushala is not just a cow sanctuary, but its main objective is to make Mother Earth free of toxins through the protection of cows and the cooperation of Gita knowledge, and to pave the way for the establishment of Sanatan Dharma and Shri Ram Rajya in the world by education, culture, service, and sacrifice, and to make India the world guru again.</p>
                                            </div>
                                            <div className="banner-btn-container">
                                                <a href='#' className='gs_main-banner-btn'>Read More </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 position-relative">
                                        <div className="img-container cow-img-container">
                                            <img src={cowImg} alt="banner-cow-img" className=' position-absolute top-0 right-0' />
                                        </div>
                                        <div className="img-container background-img-container ">
                                            <img src={cowImageBg} alt="banner-bg-img" className='position-absolute top-0 right-0 bottom-0' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default MainBanner