import React from "react";
import './ContactUsBanner.css';
function ContactUsBanner({title}){
return(
  
    <div className="contact-us-banner">
      <div className="banner-content">
        <h1>{title}</h1>
      </div>
    </div>
)
}
export default ContactUsBanner;