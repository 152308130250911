import React from 'react'
import ThreeDivLine from '../../ThreeDivLine'
import bannerCircleImage from '../../../assets/Banner/banner-circle-image.png'
import bannerCircleImage2 from '../../../assets/Banner/banner-circle-image-2.png'
function BannerCircleImage() {
    return (
        <>
            <div className="container ga__banner-circle-image">
                <div className="row">
                    <div className="col-lg-6 content-container">
                        <div className="heading-container">
                            <h1 >Protect Cow, Protect Future:</h1>
                            <h1 >Support Gau Raksha Today!</h1>
                            <div className="line-container">
                                <ThreeDivLine backgroundColor='#34AD27A3' />
                            </div>
                        </div>

                        <div className="description-container">
                            <p>
                            Under the Shree Geeta Gau Raksha initiative, a wide range of devotees come here for gau raksha and offer services to cow protection centers. The ultimate concept of Gau Raksha is rooted in Hinduism that aims to protect all cow breeds from exploitation, slaughterhouses, and any form of harmfulness. Our Gau Rakshaks protect different cow breeds and support the religious ethos with a belief system. Shree Geeta Gaushala plays a significant role in practicing Indian culture and traditions in our society.
                            </p>
                        </div>

                    </div>
                    <div className="col-lg-6">
                        <div className="img-container-circle d-flex align-items-center justify-content-center justify-content-lg-end">
                            <div className="circle-image ">
                                <img
                                    src={bannerCircleImage}
                                    alt="Cows"
                                    className="img-fluid"
                                />
                                <div className="circle-text">
                                    <img src={bannerCircleImage2} alt="" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default BannerCircleImage