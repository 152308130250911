import React from 'react'
import BannerCircleImage from '../../components/about-us-page/banner/BannerCircleImage'
import QuoteComponent from '../../components/QuoteComponent'
import TabbedContent from '../../components/TabbedContent'
import AboutUsFaq from '../../components/about-us-page/faq/AboutUsFaq'
function OurMission() {
  return (
    <>
        <BannerCircleImage />
        <QuoteComponent />
        <TabbedContent />
        <AboutUsFaq />
    </>
  )
}

export default OurMission