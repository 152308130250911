import React from "react";
import AboutUsBanner from "../../../components/about-us-page/banner/AboutUsBanner";
import CoreVision from "../../../components/about-us-page/banner/CoreVision";
import AboutUsCards from "../../../components/about-us-page/banner/AboutUsCards";

import OurMissionBanner from '../../../components/about-us-page/banner/OurMissionBanner';
import coreVisionImg from '../../../assets/Banner/core-vision-img.png'
const OurHistory =()=>{
    return(
        <>
      
        
           <CoreVision/>
           <AboutUsBanner/>
           <OurMissionBanner  image={coreVisionImg} title={"Our Mission: More Than Just a Gaushala"} description={["Shri Hanuman Prasad Baheti is the Founder and Chief Trustee of Shree Geeta Gaushala. There’s a plan to build a multi-purpose building that includes Kamdhenu Hospital, Shree Panchgavya Ayurveda Hospital, Panch Tatva Chikitsalaya (Acupressure Treatment), a free residential training camp for 300 workers of social welfare organizations. In addition to this, the fourth floor will be used for various social programs. On the other hand, the fifth floor will be carrying two halls of 20,000 square feet each to help 500 people."]}/>
           <AboutUsCards/>
        </>
    )

}

export default OurHistory;