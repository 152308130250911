import React from 'react'
import PaymentForm from '../../components/forms/PaymentForm'
import OurMissionBanner from '../../components/about-us-page/banner/OurMissionBanner'
import { assets } from '../../assets/asset'
import { OUR_ACTIVITIES_CARDS } from '../../constants/OurActivities'
import './OurActivities.css'
import ActivitiesCardItem from '../../components/cards/ActivitiesCardItem'

function OurActivities() {
  return (
    <>
      <OurMissionBanner title={"From Cow Sanctuary Toward Social Good"} image={assets.bannersImg.cowFeedingGroupImg} description={["The core philosophy of Geeta Ashram’s CSR (Corporate Social Responsibility) is based on preserving cow breed's lives and promoting community welfare. We encourage eco-friendly activities with the sustainable use of cow dung in organic fertilizers and bio-fuel development processes. Additionally, it’s our corporative moral and social responsibility to collectively participate in social good & make this world a better living place.","With our model of cow care, we also strengthen the cultural & religious values of gau mata in Hinduism. Thus, you can contribute to Geeta Gaushala’s CSR initiatives of animal welfare ethics, sustainable development with environment protection, and community welfare as a whole."]}/>
      {/* <PaymentForm /> */}
    <ActivitiesCardContainer/>

    </>

  )
}

export default OurActivities

function ActivitiesCardContainer(){
  return(
   <div className="ga__activities-card-container container">
<div className="row">
  <div className="col-12">
    <div className="activites-titile">
      <h1 className="title">GEETA GAUSHALA’s CSR Activities</h1>
    </div>
    <div className="activites-description">
      <p className="description">We are overwhelmed to introduce you to Geeta Gaushala’s cooperative social activities often. Look at the below section to get the gist of all quickly.</p>
    </div>
    <div className="activities-card-container-item">
    {OUR_ACTIVITIES_CARDS.map((activity, index) => (
        <ActivitiesCardItem 
          key={index}
          title={activity.title}
          description={activity.description}
          image={activity.image}
        />
      ))}
  </div>
  </div>
  
</div>
   </div>
  )
}