import axios from 'axios';
import { baseDomianURL } from '../constants/config';

// Create an instance of axios with default configurations
const apiClient = axios.create({
  baseURL: baseDomianURL + '/api/blogs', 
  headers: {
    'Content-Type': 'application/json',
    // Add other custom headers if needed
  },
});

// Function to fetch card data
export const fetchCardData = async (page = 0, size = 3) => {
    try {
      const response = await apiClient.get(`/summaries?page=${page}&size=${size}`); // Adjust the endpoint as needed
      return response.data;
    } catch (error) {
      console.error('Error fetching card data:', error);
      throw error;
    }
  };
  
  // Function to create a new blog post (requires authorization)
  export const createBlog = async (blogData, token) => {
    try {
      const response = await apiClient.post(
        '/blogs', // Adjust this to the correct blog creation endpoint
        blogData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add the authorization token here
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error creating blog:', error);
      throw error;
    }
  };
