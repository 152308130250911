import React from 'react';

const AccordionItem = ({ question, answer, isOpen, onClick, index }) => {
    return (
        <div className="accordion-item  faq_item mb-4">
            <h2 className="accordion-header" id={`heading${index}`}>
                <button
                    className={`accordion-button ${isOpen ? '' : 'collapsed'}`}
                    type="button"
                    onClick={onClick}
                    aria-expanded={isOpen}
                    aria-controls={`collapse${index}`}
                >
                    {question}
                </button>
            </h2>
            <div
                id={`collapse${index}`}
                className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`}
                aria-labelledby={`heading${index}`}
                data-bs-parent="#faqAccordion"
            >
                <div className="accordion-body">
                    {answer}
                </div>
            </div>
        </div>
    );
};

export default AccordionItem;
