// cards
import blogcardImg from './cards/blog_card_img.png'
import childImg from './cards/child-image.png'
import cowGroupImg from './cards/cows-group.png'
import environmentProtectionImg from './cards/environment-protection.png'
import foodDonationImg from './cards/food-donation.png'
import geetaImg from './cards/geeta-img.png'
import medicineImg from './cards/medicine.png'
import organicFilterImg from './cards/organic-filter.png'
import yogaImg from './cards/yoga.png'
import activityCardImg from './cards/activity-card-img.png'
import cowFeedingGroupImg from './Banner/cowfeedinggroup.png'
import cowImgBlog1 from './cards/cow-img-blog.jpg'
import cowImgBlog2 from './cards/cow-img-blog2.webp'
import cowImgBlog3 from './cards/cow-img-blog3.jpg'
import cowImgBlog4 from './cards/cow-img-blog4.jpg'
import cowImgBlog5 from './cards/cow-img-blog5.webp'
// carousel
import carouselImgOne from './carousel/carousel-img-1.jpg'
import carouselImgTwo from './carousel/carousel-img-2.jpg'
import carouselImgThree from './carousel/carousel-img-3.jpg'
import carouselImgFour from './carousel/carousel-img-4.jpg'
import carouselImgFive from './carousel/carousel-img-5.jpg'
// icons
import arrowDownIcon from './icons/arrow-down-sign-to-navigate.png'
import authorIcon from './icons/author.png'
import ayurvedaIcon from './icons/ayurveda-icon.png'
import calenderDateMonthIcon from './icons/calendar-date-month.svg'
import calenderDateYearIcon from './icons/calendar-date-one.svg'
import cancelVarOneIcon from './icons/cancel-icon.png'
import cancelVarTwoIcon from './icons/cancel-icon2.png'
import cowIcon from './icons/gaumata-icon.png';
import hospitalIcon from './icons/hospital-icon.png';
import earthIcon from './icons/environment-icon.png';
import mpDepartmentIcon from './icons/mp-ayog.png';
import incomeTaxDIcon from './icons/incometaxd.png';
import nitiayogIcon from './icons/niti-ayog.png'
import flagIcon from './icons/sanatan-icon.png'
import ideaIcon from './icons/idea-icon.png';
import questionIcon from './icons/question-sign.png';
import targetIcon from './icons/target-icon.png';
import puzzleIcon from './icons/puzzle-icon.png';
import warningIcon from './icons/warning-icon.png';
import phoneIconVar2 from './icons/phone-fill.png';
import outlineGlobal from './icons/global-line.png';
import mailIcon from './icons/mail.png';
import publishIcon from './icons/send.png'
export  const assets={
    cardsImg:{
      blogCardImg:blogcardImg,
      childImg:childImg,
      cowGroupImg:cowGroupImg,
      environmentProtectionImg:environmentProtectionImg,
      foodDonationImg:foodDonationImg,
      geetaImg:geetaImg,
      medicineImg:medicineImg,
      organicFilterImg:organicFilterImg,
      yogaImg:yogaImg,
      activityCardImg:activityCardImg,
      cowImgBlog1:cowImgBlog1,
      cowImgBlog2:cowImgBlog2,
      cowImgBlog3:cowImgBlog3,
      cowImgBlog4:cowImgBlog4,
      cowImgBlog5:cowImgBlog5
    },
    bannersImg:{
        cowFeedingGroupImg:cowFeedingGroupImg
    },
    carouseImg:{
      carouselImgOne:carouselImgOne,
      carouselImgTwo:carouselImgTwo,
      carouselImgThree:carouselImgThree,
      carouselImgFour:carouselImgFour,
      carouselImgFive:carouselImgFive
    },
    icons:{
      arrowDownIcon:arrowDownIcon,
      authorIcon:authorIcon,
      ayurvedaIcon:ayurvedaIcon,
      calenderDateMonthIcon:calenderDateMonthIcon,
      calenderDateYearIcon:calenderDateYearIcon,
      cancelVarOneIcon:cancelVarOneIcon,
      cancelVarTwoIcon:cancelVarTwoIcon,
      cowIcon:cowIcon,
      hospitalIcon:hospitalIcon,
      earthIcon:earthIcon,
      mpDepartmentIcon:mpDepartmentIcon,
      incomeTaxDIcon:incomeTaxDIcon,
      nitiayogIcon:nitiayogIcon,
      targetIcon:targetIcon,
      puzzleIcon:puzzleIcon,
      warningIcon:warningIcon,
      questionIcon:questionIcon,
      ideaIcon:ideaIcon,
      flagIcon:flagIcon,
      phoneIconVar2:phoneIconVar2,
      outlineGlobal:outlineGlobal,
      mailIcon:mailIcon,
      publishIcon:publishIcon
    }
    
}