import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./BlogListing.css";
import author from '../../assets/icons/author.png'
import { assets } from '../../assets/asset'
import ThreeDivLine from "../../components/ThreeDivLine";
import { fetchCardData } from '../../api/blogsService';
const BlogListing = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 10;

  // Simulate fetching blogs from a static array (fake data)

  useEffect(() => {
    const getCards = async () => {
      try {
        const data = await fetchCardData(0,15);
        setBlogs(data); // Assuming you want to display first 4 cards
      } catch (err) {
        setError('Failed to load card data.');
      } finally {
        setLoading(false);
      }
    };

    getCards();
  }, []);

  // Pagination logic
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="blog-listing-container">
      <div className="blog-title-container">
        <h2 className="blog-title">Blogs</h2>
        <div className="line-container">
          <ThreeDivLine backgroundColor="#34AD27A3" />
        </div>
      </div>

      <div className="blog-list">
        {blogs.map((blog) => (
          <NavLink
            className="blog-link"
            key={blog.id}
            to={`/blog/${blog.id}`}
          >
            <div className="card card__blogs">
              <img
                src={blog.imageUrl || assets.cardsImg.blogCardImg}
                className="card-img"
                alt={blog.title}
                loading="lazy"
              />
              <div className="card-body">
                <h3 className="card-title">{blog.title}</h3>
                {/* <p className="card-text">{blog.description}</p> */}
              </div>

              <div className="card-body card-body-bottom d-flex flex-row align-items-center">
                <div className="img-container">
                  <img
                    className="author-img"
                    src={blog.authorImage || author}
                    alt={blog.authorName}
                    width={36}
                    height={36}
                  />
                </div>
                <div className="name-container">
                  <p className="poster-name">{blog.authorName	}</p>
                  {/* <p className="time-to-read">{blog.timeToRead}</p> */}
                </div>
              </div>
            </div>
          </NavLink>
        ))}
      </div>

     
      {/* <div className="pagination">
       
        {currentPage > 1 && (
          <button
            onClick={() => paginate(currentPage - 1)}
            className="pagination-btn prev-btn"
          >
            Prev
          </button>
        )}

      
        {Array.from(
          { length: Math.ceil(blogs.length / blogsPerPage) },
          (_, i) => (
            <button
              key={i + 1}
              onClick={() => paginate(i + 1)}
              className={`pagination-btn ${
                currentPage === i + 1 ? "active" : ""
              }`}
            >
              {i + 1}
            </button>
          )
        )}

        
        {currentPage < Math.ceil(blogs.length / blogsPerPage) && (
          <button
            onClick={() => paginate(currentPage + 1)}
            className="pagination-btn next-btn"
          >
            Next
          </button>
        )}
      </div> */}
    </div>
  );
};

export default BlogListing;
