import React from 'react'
import PaymentForm from '../../../components/forms/PaymentForm';
import AboutUsFaq from '../../../components/about-us-page/faq/AboutUsFaq';

function Payment_Form() {
  return (
   <>
   <PaymentForm/>
    <AboutUsFaq/>
   </>
  )
}

export default Payment_Form;