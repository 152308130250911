import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';  // To sanitize HTML before sending it to backend
import axios from 'axios';
import './Blogs.css'
import { assets } from '../../assets/asset';
import { baseDomianURL } from '../../constants/config';
const BlogEditor = () => {
  const navigate = useNavigate();
  // State to store the blog title and content
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  // Handler for the blog title input
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  // Handler for blog content
  const handleContentChange = (value) => {
    setContent(value);
  };

  const htmlToJson = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const elements = Array.from(doc.body.children);
  
    function elementToJson(el) {
      const tag = el.tagName.toLowerCase();
      const jsonElement = { tag };
  
      // Handle attributes like class and href (for links)
      if (el.getAttribute('class')) {
        jsonElement.class = el.getAttribute('class');
      }
      if (tag === 'a' && el.getAttribute('href')) {
        jsonElement.href = el.getAttribute('href');
      }
      if (tag === 'img' && el.getAttribute('src')) {
        jsonElement.className = "img_content";
        jsonElement.src = el.getAttribute('src');
      }
  
      // Recursively process child elements (for nested tags)
      if (el.children.length > 0) {
        jsonElement.children = Array.from(el.children).map(child => elementToJson(child));
      } else {
        // For tags that contain text or other inline formatting
  
        // Replace &nbsp; with actual space to avoid incorrect handling
        const content = el.innerHTML || el.textContent;
        jsonElement.content = content.replace(/&nbsp;/g, ' ');  // Replace non-breaking spaces with normal spaces
      }
  
      return jsonElement;
    }
  
    // Convert all top-level elements to JSON
    const jsonResult = {
      elements: elements.map(el => elementToJson(el))
    };
  
    return jsonResult;
  };
  
  // Function to handle the form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Sanitize the content before submitting
    const sanitizedTitle = DOMPurify.sanitize(title);
    const sanitizedContent = DOMPurify.sanitize(content);
    try {
     
     let jsonData = htmlToJson(sanitizedContent);
      //Post the sanitized data to the backend
      const response = await axios.post(baseDomianURL+'/api/blogs/create', {
        title: sanitizedTitle,
        content: JSON.stringify(jsonData),
        author: "Shree GeetaGaushala",
        isPublished: true
      });
      if(response){
        alert('Blog saved successfully');
        navigate(`/blog/${response.data.id}`);  // Redirect to the blogs page after saving the blog
        return;  // Exit the function after successful submission to avoid unnecessary error logging
      }

    } catch (error) {
      console.error('Error saving blog:', error);
    }
  };

  return (
    <div className="blog-editor-container">
      <h3 className='editor-heading'>Create Blog</h3>
      
      <form onSubmit={handleSubmit}>
        <div className='blog-form'>
          <label>Title</label>
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            className="form-control"
            placeholder="Enter your blog title"
          />
        </div>

        <div className='blog-form-content'>
          <ReactQuill
            value={content}
            onChange={handleContentChange}
            modules={BlogEditor.modules}
            formats={BlogEditor.formats}
            theme="snow" // Theme provided by Quill
          />
        </div>

        <button type="submit" className="btn btn-primary mt-3 publish-btn">
        <img src={assets.icons.publishIcon} width={20} height={20} alt="" />  Publish
        </button>
      </form>
    </div>
  );
};

// Quill modules and formats
BlogEditor.modules = {
    toolbar: [
        [{ 'font': [] }, { 'size': [] }],                // Font and size
        [{ 'header': '1' }, { 'header': '2' }, { 'header': [3, 4, 5, 6] }, { 'header': false }],  // Headers
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],     // Lists
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // Indentation
        [{ 'align': [] }],                                // Text alignment
        ['bold', 'italic', 'underline', 'strike'],        // Text formatting
        ['blockquote', 'code-block'],                     // Blockquote and code block
        [{ 'color': [] }, { 'background': [] }],          // Text color and background
        ['link', 'image', 'video'],                       // Links, images, and videos
        ['clean']                                         // Clear formatting
      ]
};

BlogEditor.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'align',
  'link', 'image'
];

export default BlogEditor;
