import React from 'react'

function AboutUsBanner() {
    return (
        <section className="banner">
            <div className="banner-overlay">
                <h1>गौमाता व गीता ज्ञान द्वारा विश्व में सर्वत्र सनातन धर्म की पुनः स्थापना करना</h1>
            </div>
        </section>

    )
}

export default AboutUsBanner;




// import React from 'react';
// import bannerImage from '../../../assets/Banner/banner-image.png'; // Update the path to your image file

// function Banner() {
//     return (
//     );
// }

// export default Banner;
