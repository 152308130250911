import React from 'react'
import ThreeDivLine from '../../../components/ThreeDivLine'
import cowBlackImg from '../../../assets/Banner/who-we-are-black-img.png'
import cowIcon from '../../../assets/icons/cow-silhouette.png'


function WhoWeAre() {
    return (
        <section className="who-we-are py-5">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 text-content">
                        <div className="main-heading-container">
                            <div className="heading-container d-inline-block">
                                <h1>Who We Are</h1>
                                <div className="line-container">
                                    <ThreeDivLine backgroundColor='#34AD27A3' />
                                </div>
                            </div>

                        </div>
                        <p>
                            Shree Geeta Gaushala parmath santha popularly known as “GEETA GAUSALA” is situated in Jorhat, Assam (India). Our primary objective is to protect Desi cows, establish Sanatan Dharma, recreate Shri Ram Rajya, and spread the knowledge of the Bhagavad Gita throughout. We are rooted for cow protection.
                        </p>
                        <a href="#" className="btn btn-primary join-btn">Join With Us</a>
                    </div>
                    <div className="col-lg-6 image-content position-relative">
                        <img src={cowBlackImg} className="img-fluid rounded img-withback-green" alt="Man with cow" />
                        <div className="cows-saved p-2  d-flex flex-column align-items-center position-absolute">
                            <img src={cowIcon} alt="cow-silhouette" className="cow-icon-img" width={44} height={44} />
                            <span className="cow-count">2100+</span>
                            <p className="mb-0">Cows Saved</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhoWeAre