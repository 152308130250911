import cowIcon from '../assets/icons/cowIcon.png';
import foodIcon from '../assets/icons/cowIcon.png';
import medicalIcon from '../assets/icons/mediaclIcon.png'

export const ACHIVEMENTS = [
    {
        image: cowIcon,
        alt:"cow",
        achivement:"2000+",
        description: "Cows Safegaurded"
    },
    {
        image: foodIcon,
        alt:"food",
        achivement:"3 Lakh+",
        description:"Kg Nutriment Mothly"
    },{
        image: medicalIcon,
        alt:"medical",
        achivement:"1000+",
        description:"Injured Cow Rehabilitated"
    }
]