import React from 'react'
import QuoteComponent from '../../components/QuoteComponent'
import TabbedContent from '../../components/TabbedContent'
import BannerCircleImage from '../../components/about-us-page/banner/BannerCircleImage'
import AboutUsFaq from '../../components/about-us-page/faq/AboutUsFaq'

function More() {
  return (
    <>
      <BannerCircleImage />
      <QuoteComponent />
      <TabbedContent />
      <AboutUsFaq />
    </>
  )
}

export default More