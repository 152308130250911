import React from 'react'
function RecognitionCard({ compImg, description, awardName, compImgAlt, }) {
    return (
        <>
            <div className="card gs_recognition-card ms-2">
                <div className="card-content">
                    <div className="img-top">
                        <img src={compImg} alt={compImgAlt} width={96} height={96} />
                    </div>
                    <div className="card-content">
                        <div className="card-title">
                            <h3 className='company-title'>{awardName}</h3>
                        </div>
                        <div className="card-description">
                            <p className='comapny-description'>{description}</p>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default RecognitionCard