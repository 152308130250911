import React from "react";
import './UserAgreement.css'
import ContactUsBanner from "../../components/about-us-page/banner/ContactUsBanner/ContactUsBanner";
function UserAgreement() {
  return (
    <>
    <ContactUsBanner title={"User Agreement"}/>
      <div className="user-aggrement">
        <h2>End User Agreement</h2>
        <p>
          By contributing to Sree Geeta Gaushala, You understand and agree to
          the following:
        </p>
        <ol>
          You are completely aware about the primary activities of Sanstha which
          include:
          <li>
            a. operating and developing Gaurakshashalas ( shelters ) for indian
            indeginous cows
          </li>
          <li>
            b. running day to day operations of gaushala which include arranging
            and storing feed for the cows, feeding the cows on regular basis,
            clean and maintain the shelters
          </li>
          <li>c. taking care of gausevaks who look after the cows</li>
          <li>
            d. planning and developing new and improved gaushalas all over the
            country
          </li>
          <li>
            e. feeding and arranging stay for people during their visit to
            Gaushalas and ashrams run by the samitis
          </li>
          <li>
            f. to conduct religious activities like yagnas for the benefit of
            humanity
          </li>
          <li>g. conducting fund raising activities for the benefit of cows</li>
          <li>
            h. making provisions for medical care of sick and old cows in the
            shelters, conducting large scale vaccination and breed improvement
            programs.
          </li>
        </ol>
        <ol>• Your donations are completed gifts and are not refundable.</ol>
        <ol>
          • You or any related third party will not receive any commercial
          benefit from the donation.
        </ol>
        <ol>
          • You do not directly or indirectly control who will be the ultimate
          beneficiary of the donation.
        </ol>
        <ol>
          • You have imposed no conditions or restrictions preventing the
          samitie from using the contribution to further its charitable mission
          and comply with any remote legal and regulatory requirements.
        </ol>
        <ol>
          • You may have advisory privileges on the distribution of money, but
          you grant the donor samiti exclusive legal control and ownership of
          your contribution, and accordingly samiti will be the final and sole
          authority to decide whether to make further grants to any organization
          of their choice.
        </ol>
        <ol>
          • You authorize the samiti to contact you in relation to your
          donation, if necessery
        </ol>
        <ol>
          • The entirety of your donation is a tax-deductible according to the
          full extent of the law and you will be issued a receipt for that
          amount. In addition to the terms of this Agreement, You must agree to
          the terms of the terms provided below.
          <li>
            a. Indemnification; Disclaimer; Limitations on Liability.
            Indemnification. You shall defend, indemnify and hold samiti and its
            Affiliates, and their officers, employees and representatives,
            harmless from and against any and all awards, liabilities, losses,
            damages, costs and expenses (including reasonable attorneys' fees)
            incurred in connection with allegations, claims, demands, suits or
            proceedings made or brought against samiti arising out of or related
            to
            <ul>
              <li>
                (i) Use of your donation in a manner consistent with the
                Agreement
              </li>
              <li>
                (ii) Your negligence or willful misconduct. IN NO EVENT SHALL
                the samiti HAVE ANY LIABILITY TO YOU OR TO OTHER PARTY FOR ANY
                LOST PROFITS, LOSS OF DATA, OR BUSINESS INTERRUPTION, OR FOR ANY
                INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR
                CONSEQUENTIAL OR SPECIAL DAMAGES OR LOST PROFITS HOWEVER CAUSED
                arising out of your donation to the samiti Force Majeure. SAMITI
                shall be excused from performance of its obligations under the
                Agreement if such a failure to perform results from compliance
                with any act of God, fire, strike, embargo, terrorist attack,
                war, insurrection or riot, computer, telecommunications, service
                provider or hosting facility failures or delays involving
                hardware, software or power systems not within SAMITI’s
                possession or reasonable control, denial of service attacks,
                incompatibility of Your equipment, hardware or software with the
                Services, acts or omissions of vendors or suppliers,
                transportation and telecommunications difficulties, or other
                causes beyond the reasonable control; provided that such
                non-performing party immediately notifies the other party of the
                failure and undertakes all commercially reasonable efforts to
                resume performance as soon as possible after the occurrence of
                the force majeure event. Any delay resulting from any of such
                causes shall extend performance accordingly or excuse
                performance, in whole or in part, as may be reasonable under the
                circumstances. Governing Law; Jurisdiction; Venue; Severability
                of Provisions. This Agreement is governed by the laws of state
                of Assam, India . Any legal proceedings arising from or relating
                to this Agreement shall be brought exclusively in the courts
                located in Assam and the parties hereby consent to the personal
                jurisdiction and venue of such courts. All parts of this
                Agreement apply to the maximum extent permitted by law. The
                parties both agree that if any provision of this Agreement is
                found by a court of competent jurisdiction to be unenforceable
                as written, then that part will be replaced with terms that most
                closely match the intent of the unenforceable provision to the
                extent permitted by law.
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </>
  );
}
export default UserAgreement;
