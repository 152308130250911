import React from 'react'
import { ACHIVEMENTS } from '../../constants/bannerCostant'
import ThreeDivLine from '../ThreeDivLine'
function ActivityBanner() {
    return (
        <>
            <div className="gs__activity-banner container-fluid">
                <div className="row d-flex flex-column flex-md-row py-3">
                    <div className="col-12 col-md-3 d-flex align-items-center justify-content-center">
                        <div className="achivement-container">
                            <h1 className='achivement__title text-white'>Achievements</h1>
                            <div className="color-strip">
                                <ThreeDivLine backgroundColor='#34AD276E' />
                            </div>
                        </div>


                    </div>
                    {ACHIVEMENTS.map((properties, index) => (
                        <div key={index} className="col-12 col-md-3 text-white">
                            <div className="d-flex flex-row gap-2 py-4 align-items-center justify-content-center">
                                <div className="gs__activity-banner-img">
                                    <img className='activity-banner-image' src={properties.image} alt={properties.alt} />
                                </div>
                                <div className="content__achivement d-flex flex-column gap-1">
                                    <h2 className='content__title'>
                                        {properties.achivement}
                                    </h2>
                                    <p className='content__desc'>
                                        {properties.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default ActivityBanner