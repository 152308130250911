import React from "react";
import journeyTillCowImg from '../../../assets/Banner/our-journey-cow-img.png'
import ThreeDivLine from "../../ThreeDivLine";
const JourneyTillNow = () => {
    return (
        <section className="journey-till-now py-5">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 mb-4 mb-lg-0">
                        <img src={journeyTillCowImg} className="img-fluid rounded" alt="Cows in the field" />
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="main-heading-container">
                            <div className="heading-container d-inline-block">
                                <h1>Our Journey Till Now</h1>
                                <div className="line-container">
                                    <ThreeDivLine backgroundColor='#34AD27A3' />
                                </div>
                            </div>
                        </div>

                        <p>
                            On 30 May 2015, Mahamandaleshwar Shri Shri Balaknandji Maharaj, Didi Maa Ritambharaji with other respected saints established the foundation of a Gaushala. At first, we saved 98 cows from a slaughterhouse by cow smugglers on March 30, 2015. Since then, We have been taking care of more than 2100+ cows. Of them all, 1145 cows were rescued from slaughterhouses while 980 suffered from several accidents or illnesses. Geeta Ashram proves to be the first source of solution. The Ashram has been making continuous efforts to improve the lives of the gau mata breed. For instance, saving the local lakhimi breed and making it more productive. The work of providing medical treatment to sick or injured cows in road accidents by bringing them to the ashram via ambulance has been done since the establishment of the ashram.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default JourneyTillNow;