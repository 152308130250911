import React from 'react';
// import './QuoteComponent.css'; // Import the CSS file for styling
import quoteImage from '../assets/gandhiji-image.png'; // Import the image

const QuoteComponent = () => {
    return (
        <div className="container-fluid quote-container gs__quote-banner  text-center p-3">
            <div className="row g-0 align-items-center">
                <div className="col-md-9">
                    <div className="card-body">
                        <p className="card-text  quote-heading">
                        "Cow protection is the eternal dharma of India."
                        </p>
                        <p className="card-text quote-name"><small className="text-muted">- Dr. Rajendra Prasad, 1st President of India</small></p>
                    </div>
                </div>
                <div className="col-md-2">
                    <img src={quoteImage} alt="Gandhi Quote" className="img-fluid rounded-start" />
                </div>

            </div>
        </div>
    );
}

export default QuoteComponent;
