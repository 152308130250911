import React from 'react';
import ThreeDivLine from '../../ThreeDivLine';

function OurMissionBanner({ title, image, description }) {
    return (
        <section className="about-us-our-mission  py-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="main-heading-container">
                            <div className="heading-container -d-inline-block">
                                <h1>{title}</h1>
                                <div className="line-container">
                                    <ThreeDivLine backgroundColor='#34AD27A3' />
                                </div>
                            </div>
                        </div>

                        {description.map((para, index) => (
                            <p key={index}>{para}</p>
                        ))}
                    </div>
                    <div className="col-lg-6 position-relative about-us-our-mission-content">
                        <img src={image} className="image-about-us-banner" alt="Core Vision" />
                        <div className="corner-design"></div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OurMissionBanner;

