import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import successIcon from '../../assets/icons/success-icon2.png';
import warningIcon from '../../assets/icons/warning-icon2.png';
import cancelIcon from '../../assets/icons/cancel-icon2.png';
import axios from 'axios';
import { FiDownload } from 'react-icons/fi';
import {baseDomianURL} from '../../constants/config';

const PaymentStatus = () => {
  const [paymentData, setPaymentData] = useState(null);
  const [status, setStatus] = useState('loading'); // Initial status as loading
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get('orderId');

  useEffect(() => {


    if (orderId) {
      const fetchPaymentStatus = async () => {
        try {
          const verifyUrl = `${baseDomianURL}/api/payment/status/${orderId}`;
          const data = await axios.get(verifyUrl);
          setPaymentData(data.data.data);
          setStatus(data.data.status); // Assuming `data.status` contains the payment status
        } catch (error) {
          console.error("Error fetching payment status:", error);
          setStatus('error');
        }
      };

      fetchPaymentStatus();
    } else {
      setStatus('error'); // Handle case when orderId is not present
    }
  }, [orderId]);

  const renderStatus = () => {
    switch (status) {
      case 'success':
        return (
          <div className="payment-status-container text-center">
            <img src={successIcon} alt="Payment Success" width={48} height={48} />
            <h2 className="text-success mt-3">Payment Success</h2>
          </div>
        );
      case 'Pending':
        return (
          <div className="payment-status-container text-center">
            <img src={warningIcon} alt="Payment Pending" width={48} height={48} />
            <h2 className="text-warning">Payment Pending</h2>
          </div>
        );
      case 'Failed':
        return (
          <div className="payment-status-container text-center">
            <img src={cancelIcon} alt="Payment Failed" width={48} height={48} />
            <h2 className="text-danger">Payment Failed</h2>
          </div>
        );
      case 'error':
        return <h2 className="text-danger">Error fetching payment status</h2>;
      default:
        return <h2>Loading...</h2>;
    }
  };

  return (
    <div className="container mt-4 gs__payment-status">

      <div className="card">
        <div className="card-header">
          <h3>Payment Details</h3>
        </div>
        <Link to={`/payment/payment_recipt?orderId=${orderId}`} className="btn download-recipt-btn btn-primary mt-3 end-50">
          <FiDownload size={16} className="" />
        </Link>
        <div className="card-body">
          {renderStatus()}
          {paymentData && (
            <>
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <p><strong>Name:</strong> {paymentData.name}</p>
                  <p><strong>Email:</strong> {paymentData.email}</p>
                  <p><strong>Phone Number:</strong> {paymentData.phone}</p>
                </div>
                <div className="col-md-6">
                  <p><strong>Amount:</strong> INR {(paymentData.amount / 100).toFixed(2)}</p>
                  <p><strong>Address: </strong>{paymentData.houseNo} {paymentData.streetName} {paymentData.state}
                    {paymentData.city} {paymentData.pinCode}</p>
                  <p><strong>Date:</strong> {new Date(paymentData.eventDate).toLocaleDateString()}</p>
                </div>
              </div>
              <hr />
              <p><strong>Order ID:</strong> {paymentData.orderId}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentStatus;
