import React from 'react';
import ThreeDivLine from '../../ThreeDivLine';
import coreVisionImg from '../../../assets/Banner/core-vision-img.png'; // Replace with the correct path to your image

function CoreVision() {
    return (
        <section className="core-vision py-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 position-relative core-vision-content">
                        <div className="img-container">
                        <img src={coreVisionImg} className="img-fluid rounded mb-3" alt="Core Vision" />
                        <div className="corner-design"></div>
                        </div>
                       
                    </div>
                    <div className="col-lg-6">
                        <div className="main-heading-container">
                            <div className="heading-container d-inline-block">
                                <h1>Core Vision</h1>
                                <div className="line-container">
                                    <ThreeDivLine backgroundColor='#34AD27A3' />
                                </div>
                            </div>
                        </div>

                        <p>
                            The Ashram has eight spacious cow sheds with a facility for drinking water to keep it all clean & neat at all time. Our Ashram compound regularly morning and evening aarti of the Gau Mata in the Kamdhenu Temple which is situated near the gates. Also, the Bhairat Mata Temple & Shree Dhanvantri Vanika have herbal medicines plants, right next to the Kamdhenu temple. The Kamdhenu Cow Hospital provides better treatment for sick or injured cows. The Ashram has its own Cow Ambulance with a ramp to safely load, injured, and sick cows enter the Ashram. Additionally, the Children's Park in the Ashram attracts the children who come with their parents. In the main compound, there is the Surabhi Organic Fertilizer Project which produces Fertilizer and Vermiwash. Its Ashram’s efforts to encourage our peasants to switch to organic agriculture and farm activities. The Ashram has been established not only to provide life and homes to cows but to achieve the fulfillment for her high quality life. We organize workshops to educate farmers about producing green fodder for the cows, switching to organic culture, and making cows happy. We have the Vidyapeeth that provides modern education based on Indian culture and character building.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CoreVision;
