import React from 'react';
import faqimage1 from '../../../assets/Banner/about-us-faq-img-1.png'
import faqimage2 from '../../../assets/Banner/about-us-faq-img-2.png'
import faqimage3 from '../../../assets/Banner/about-us-faq-img-3.png'
import { ABOUT_US_FAQS } from '../../../constants/AboutUsConstant';

function AboutUsFaq() {
    return (
        <div className="container-wrapper-gs__about-us-faq">
            <div className="container mt-5 gs__about-us-faq">
                <h2 className="heading-1 text-center ">Your Questions Answered</h2>
                <h3 className="heading-2 text-center ">Get informed now.</h3>
                <h4 className="heading-3 text-center ">Answers to your common questions</h4>

                <div className="row">
                    <div className="col-lg-8">
                        <div className="secondary-heading-container">
                            <p className='secondary-heading-one'>Frequently Asked Questions</p>
                            <p className='secondary-heading-two'>Can't find what you're looking for? Get in touch with our team and we will gladly help out.</p>
                        </div>
                        <div className="accordion" id="accordionExample">
                            {ABOUT_US_FAQS.map((faq, index) => (
                                <div className="accordion-item" key={index}>
                                    <h2 className="accordion-header" id={`heading${index}`}>
                                        <button
                                            className={`accordion-button ${index === 0 ? '' : 'collapsed'}`}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse${index}`}
                                            aria-expanded={index === 0 ? 'true' : 'false'}
                                            aria-controls={`collapse${index}`}
                                        >
                                            {index + 1}.{faq.question}
                                        </button>
                                    </h2>
                                    <div
                                        id={`collapse${index}`}
                                        className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                                        aria-labelledby={`heading${index}`}
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            {faq.answer}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="faq-image-container">
                            <div className="first-image-container">
                                <img src={faqimage1} alt="" className='faq-image image-first' />
                            </div>
                            <div className="second-image-container">
                                <img src={faqimage2} alt="" className='faq-image image-second' />
                            </div>
                            <div className="third-image-container">
                                <img src={faqimage3} alt="" className='faq-image image-third' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUsFaq;