import React, { useState,useRef } from 'react';
import hide from '../../assets/icons/nonvisible.png'
import show from '../../assets/icons/visible.png'
function LoginModel() {
    const [isLogin, setIsLogin] = useState(true);
    const [loginData, setLoginData] = useState({
        phoneOrEmail: '',
        password: '',
    });
    const [signupData, setSignupData] = useState({
        name: '',
        email: '',
        phoneNo: '',
        dob: '',
        password: '',
        confirmPassword: '',
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const modalRef = useRef(null);

    const toggleForm = () => {
        setIsLogin(!isLogin);
        setErrors({});
    }
    const handleModalToggle = () => {
        if (modalRef.current) {
          modalRef.current.classList.toggle('show');  // Adds or removes 'active' class
        }
      };

      const handleModalClose = () => {
        if (modalRef.current) {
          modalRef.current.classList.remove('show');
        } 
        const modalBackdrop = document.querySelector('.modal-backdrop');
        if (modalBackdrop) {
          modalBackdrop.remove();
        }

        document.body.style.overflow = ''; // Remove overflow style
        document.body.classList.remove('modal-open');
    }
    const handleLoginChange = (e) => {
        const { id, value } = e.target;
        setLoginData({ ...loginData, [id]: value });
    }

    const handleSignupChange = (e) => {
        const { id, value } = e.target;
        setSignupData({ ...signupData, [id]: value });
    }

    const validateLogin = () => {
        const newErrors = {};
        if (!loginData.phoneOrEmail) newErrors.phoneOrEmail = 'Mobile or Email is required';
        if (!loginData.password) newErrors.password = 'Password is required';
        return newErrors;
    }

    const validateSignup = () => {
        const newErrors = {};
        if (!signupData.name) newErrors.name = 'Name is required';
        if (!signupData.email) newErrors.email = 'Email is required';
        if (!signupData.phoneNo) newErrors.phoneNo = 'Phone No is required';
        if (!signupData.dob) newErrors.dob = 'Date of Birth is required';
        if (!signupData.password) newErrors.password = 'Password is required';
        if (!signupData.confirmPassword) newErrors.confirmPassword = 'Confirm Password is required';
        if (signupData.password !== signupData.confirmPassword) newErrors.confirmPassword = 'Passwords do not match';
        return newErrors;
    }

    const handleSubmit = () => {
        const newErrors = isLogin ? validateLogin() : validateSignup();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setErrors({});
            if (isLogin) {
                console.log('Login Data:', loginData);
            } else {
                console.log('Signup Data:', signupData);
            }
        }
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    return (
        <>
            <button type="button" className="btn  btn-login-modal" onClick={handleModalToggle} data-bs-toggle="modal" data-bs-target="#exampleModal">
                Login/Signup
            </button>
            <div className="modal fade gs__login-model" ref={modalRef} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">{isLogin ? 'Login' : 'Signup'}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModalClose} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {isLogin ? (
                                <div>
                                    <div className="mb-3">
                                        <label htmlFor="phoneOrEmail" className="form-label">Mobile or Email</label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.phoneOrEmail ? 'is-invalid' : ''}`}
                                            id="phoneOrEmail"
                                            value={loginData.phoneOrEmail}
                                            onChange={handleLoginChange}
                                        />
                                        {errors.phoneOrEmail && <div className="invalid-feedback">{errors.phoneOrEmail}</div>}
                                    </div>
                                    <div className="mb-3 position-relative">
                                        <label htmlFor="password" className="form-label">Password</label>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                            id="password"
                                            value={loginData.password}
                                            onChange={handleLoginChange}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-sm  position-absolute eye-btn end-0 translate-middle-y"
                                            onClick={toggleShowPassword}
                                        >
                                            {showPassword ? <img src={hide} width={20} height={20} loading='lazy'/> : <img src={show} width={20} height={20} loading='lazy'/>}
                                        </button>
                                        {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                    </div>
                                    <button type="button" className="btn btn-link" onClick={toggleForm}>Signup</button>
                                </div>
                            ) : (
                                <div>
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Name</label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                            id="name"
                                            value={signupData.name}
                                            onChange={handleSignupChange}
                                        />
                                        {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input
                                            type="email"
                                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                            id="email"
                                            value={signupData.email}
                                            onChange={handleSignupChange}
                                        />
                                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="phoneNo" className="form-label">Phone No</label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.phoneNo ? 'is-invalid' : ''}`}
                                            id="phoneNo"
                                            value={signupData.phoneNo}
                                            onChange={handleSignupChange}
                                        />
                                        {errors.phoneNo && <div className="invalid-feedback">{errors.phoneNo}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="dob" className="form-label">Date of Birth</label>
                                        <input
                                            type="date"
                                            className={`form-control ${errors.dob ? 'is-invalid' : ''}`}
                                            id="dob"
                                            value={signupData.dob}
                                            onChange={handleSignupChange}
                                        />
                                        {errors.dob && <div className="invalid-feedback">{errors.dob}</div>}
                                    </div>
                                    <div className="mb-3 position-relative">
                                        <label htmlFor="password" className="form-label">Password</label>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                            id="password"
                                            value={signupData.password}
                                            onChange={handleSignupChange}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-sm  position-absolute eye-btn end-0 translate-middle-y"
                                            onClick={toggleShowPassword}
                                        >
                                            {showPassword ?  <img src={hide} width={20} height={20} loading='lazy'/> : <img src={show} width={20} height={20} loading='lazy'/>}
                                        </button>
                                        {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                    </div>
                                    <div className="mb-3 position-relative">
                                        <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                        <input
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                                            id="confirmPassword"
                                            value={signupData.confirmPassword}
                                            onChange={handleSignupChange}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-sm  position-absolute eye-btn end-0 translate-middle-y"
                                            onClick={toggleShowConfirmPassword}
                                        >
                                            {showConfirmPassword ? <img src={hide} width={20} height={20} loading='lazy'/> : <img src={show} width={20} height={20} loading='lazy'/>}
                                        </button>
                                        {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
                                    </div>
                                    <button type="button" className="btn btn-link" onClick={toggleForm}>Login</button>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={handleModalClose} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary btn-bg-primary" onClick={handleSubmit}>
                                {isLogin ? 'Login' : 'Signup'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoginModel;
