import React, { useState, useEffect } from 'react';
import prevBtnImg from '../../assets/icons/previousButton.png'
import nextBtnImg from '../../assets/icons/nextButton.png'
import { assets } from '../../assets/asset';
const images = [
   assets.carouseImg.carouselImgOne,
   assets.carouseImg.carouselImgTwo,
   assets.carouseImg.carouselImgThree,
   assets.carouseImg.carouselImgFour,
   assets.carouseImg.carouselImgFive,
];

const Carousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval);
    }, []);

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    return (
        <section className="carousel-main py-5">
            <div className="container  ">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-12">
                        <div className="carousel">
                            <div className="carousel-images">
                                {images.map((image, index) => (
                                    <img
                                        key={index}
                                        src={image}
                                        alt={`Slide ${index + 1}`}
                                        className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
                                    />
                                ))}
                            </div>
                            <div className="carousel-controls">
                                <button className="carousel-button prev" onClick={goToPrevious}>
                                    <img src={prevBtnImg} alt='prev-btn' />
                                </button>
                                <button className="carousel-button next" onClick={goToNext}>
                                    <img src={nextBtnImg} alt='next-btn' />
                                </button>
                            </div>
                            <div className="carousel-dots">
                                {images.map((_, index) => (
                                    <span
                                        key={index}
                                        className={`dot ${index === currentIndex ? 'active' : ''}`}
                                        onClick={() => setCurrentIndex(index)}
                                    ></span>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Carousel;
