import React, { useState } from 'react';
import ThreeDivLine from './ThreeDivLine';
import cowImage from '../assets/Banner/our-journey-cow-img.png'; // Import the image
import ideaIcon from '../assets/icons/idea-icon.png';
import questionIcon from '../assets/icons/question-sign.png';
import targetIcon from '../assets/icons/target-icon.png';
import puzzleIcon from '../assets/icons/puzzle-icon.png';
import warning from '../assets/icons/warning-icon.png';

const TabbedContent = () => {
    const [activeTab, setActiveTab] = useState('why');

    const renderContent = () => {
        switch (activeTab) {
            case 'why':
                return (
                    <div className='container tabbed-content'>
                        <div className="row flex-column-reverse flex-lg-row">
                            <div className="col-lg-6 content-img-container">
                                <img src={cowImage} alt="Cows" className="img-fluid rounded position-relative" />
                            </div>
                            <div className="col-lg-6 content-container">
                                <div className="heading-container">
                                    <h3>Why Is Gau Raksha Important?</h3>
                                </div>

                                <div className="line-container">
                                    <ThreeDivLine backgroundColor='#34AD27A3' />
                                </div>
                                <p>
                                    The people of Jorhat have been devoted to serving mother cows for years. In Hinduism, Cow breeds are truly a symbol of generous motherhood, thus Hindus see cow protection or Gau Raksha as their religious duty. Apart from this, Gau Raksha also promotes multiple environmental benefits including milk & dairy products, organic farming, and sustainable use of resources. Cows are environment-friendly animals. You can make a difference in the environment by just lowering the methane gas emission by cow's digestive system.
                                </p>
                            </div>
                        </div>
                    </div>
                );
            case 'importance':
                return (
                    <div className='container tabbed-content'>
                        <div className="row flex-column-reverse flex-lg-row">
                            <div className="col-lg-6 content-img-container">
                                <img src={cowImage} alt="Cows" className="img-fluid rounded position-relative" />
                            </div>
                            <div className="col-lg-6 content-container">
                                <div className="heading-container">
                                    <h3>Understanding the Importance of Cow Protection Centers</h3>
                                </div>

                                <div className="line-container">
                                    <ThreeDivLine backgroundColor='#34AD27A3' />
                                </div>
                                <p>
                                Cow protection centers are generally places wherein different cow breeds are protected and given food. We see cow protection centers not just gau raksha Kendra but a temple wherein religious and cultural activities take place. Hence, we can witness the cultural and religious significance of these cow protection centers. Although in the Geeta Ashram Shree Hanuman Prasad Baheti works under the direction of cow protection and their promotion across the country. The core foundation of Geeta Ashram was initiated by saving a couple of cows from being killed. Furthermore, the rural population of the country is dependent upon cows, their dairy products, and other essentials. It is seen as their livelihood and base of economic stability.
                                </p>
                            </div>
                        </div>
                    </div>

                );
            case 'impact':
                return (
                    <div className='container tabbed-content'>
                        <div className="row flex-column-reverse flex-lg-row">
                            <div className="col-lg-6 content-img-container">
                                <img src={cowImage} alt="Cows" className="img-fluid rounded position-relative" />
                            </div>
                            <div className="col-lg-6 content-container">
                                <div className="heading-container">
                                    <h3>Impact of Geeta Ashram's Gau Raksha Drive</h3>
                                </div>

                                <div className="line-container">
                                    <ThreeDivLine backgroundColor='#34AD27A3' />
                                </div>
                                <p>
                                Geeta Ashram’s gau raksha drive not only plays a significant role in improving the lives of each cow breed but also leaves a positive impact on our society. Although the spirit of cow service awakes as a result of good deeds of humans in Hindu dharmasastra. With the help of Geeta Ashram’s Gau Raksha drive Hanuman Baheti is creating new standards in the north-east focusing on cow devotion and service. 
                                </p>
                            </div>
                        </div>
                    </div>

                );
            case 'contribute':
                return (
                    <div className='container tabbed-content'>
                        <div className="row flex-column-reverse flex-lg-row">
                            <div className="col-lg-6 content-img-container">
                                <img src={cowImage} alt="Cows" className="img-fluid rounded position-relative" />
                            </div>
                            <div className="col-lg-6 content-container">
                                <div className="heading-container">
                                    <h3>Ways to Contribute To Ashram's Gau Raksha Campaign</h3>
                                </div>

                                <div className="line-container">
                                    <ThreeDivLine backgroundColor='#34AD27A3' />
                                </div>
                                <p>
                                You can contribute to Geeta Ashram’s Gau Raksha Campaign and make a big difference. To contribute to Geeta Ashram’s Gau Raksha campaign you can simply donate to cow shelters based on your economic stability. Apart from this, you can also choose to volunteer at Shree Geeta Gaushala. Under this direct volunteering, people can care for cows and their overall well-being. In this campaign, there’s a proper facility for food, shelter, and medical aid. You can follow the above ways to contribute to this gau raksha campaign which has faith in unity and beliefs. As alone, we can do so little but together, we can do so much to make the whole society a better place. 

Furthermore, educating people and conducting awareness events promotes cow devotion, care, and service for all breeds.

                                </p>
                            </div>
                        </div>
                    </div>

                );
            case 'challenges':
                return (
                    <div className='container tabbed-content'>
                        <div className="row flex-column-reverse flex-lg-row">
                            <div className="col-lg-6 content-img-container">
                                <img src={cowImage} alt="Cows" className="img-fluid rounded position-relative" />
                            </div>
                            <div className="col-lg-6 content-container">
                                <div className="heading-container">
                                    <h3>Challenges Cows Face In Today's World</h3>
                                </div>

                                <div className="line-container">
                                    <ThreeDivLine backgroundColor='#34AD27A3' />
                                </div>
                                <p>
                                In the contemporary world, cow breeds face many challenges in their day-to-day lives. As a matter of climate change is undeniable for everyone, it simultaneously affects humans and animals altogether. Along with this, there are a lot of cow breeds or Gauvansh that are on the path to extinction. They eventually don’t fit in the theory of survival of the fittest. Earth is a place where every creature exists. Thus, our collective responsibility is to make this planet a better place for everyone.
                                </p>
                            </div>
                        </div>
                    </div>

                );
            default:
                return null;
        }
    };

    return (
        <div className="tabbed-container container">
            <div className="border-dashed"></div>
            <ul className="nav nav-tabs justify-lg-content-center">
                <li className="nav-item">
                    <a
                        className={`nav-link ${activeTab === 'why' ? 'active' : ''}`}
                        onClick={() => setActiveTab('why')}
                    >
                        <div className="btn-container d-flex align-items-center justify-content-center gap-1 flex-column">
                            <div className="icon-container">
                                <img src={questionIcon} alt="question-mark" />
                            </div>
                            <div className="title-container">
                                <p >
                                    Why Is Gau Raksha Important?
                                </p>
                            </div>
                        </div>

                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${activeTab === 'importance' ? 'active' : ''}`}
                        onClick={() => setActiveTab('importance')}
                    >
                        <div className="btn-container d-flex align-items-center justify-content-center gap-1 flex-column">
                            <div className="icon-container">
                                <img src={ideaIcon} alt="idea" />
                            </div>
                            <div className="title-container">
                                <p>
                                    Understanding the Importance of Cow Protection Centers
                                </p>
                            </div>
                        </div>

                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${activeTab === 'impact' ? 'active' : ''}`}
                        onClick={() => setActiveTab('impact')}
                    >
                        <div className="btn-container d-flex align-items-center justify-content-center gap-1 flex-column">
                            <div className="icon-container">
                                <img src={targetIcon} alt="target-mark" />
                            </div>
                            <div className="title-container">
                                <p>
                                    Impact of Geeta Ashram's Gau Raksha Drive
                                </p>
                            </div>
                        </div>

                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${activeTab === 'contribute' ? 'active' : ''}`}
                        onClick={() => setActiveTab('contribute')}
                    >
                        <div className="btn-container d-flex align-items-center justify-content-center gap-1 flex-column">
                            <div className="icon-container">
                                <img src={puzzleIcon} alt="puzzle-mark" />
                            </div>
                            <div className="title-container">
                                <p>
                                    Ways to Contribute To Ashram's Gau Raksha Campaign
                                </p>
                            </div>
                        </div>

                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${activeTab === 'challenges' ? 'active' : ''}`}
                        onClick={() => setActiveTab('challenges')}
                    >
                        <div className="btn-container d-flex align-items-center justify-content-center gap-1 flex-column">
                            <div className="icon-container">
                                <img src={warning} alt="question-mark" />
                            </div>
                            <div className="title-container">
                                <p>
                                    Challenges Cows Face In Today's World
                                </p>
                            </div>
                        </div>

                    </a>
                </li>
            </ul>
            <div className="tab-content ">{renderContent()}</div>
        </div>
    );
};

export default TabbedContent;
