import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import Gallery from './pages/Gallery/Gallery';
import Contact from './pages/Contact/Contact'
import OurMission from './pages/OurMission/OurMission'
import OurActivities from './pages/OurActivities/OurActivities'
import AboutUs from './pages/AboutUs/AboutUs'
import More from './pages/More/More'
import PaymentReciptContainer from './pages/Payment/payment_recipt/PaymentReciptContainer.jsx';
// import Payment from './pages/Payment/Payment';
import Payment_Form from './pages/Payment/payment_form/Payment_Form';
import Header from './components/Layouts/Header';
import Footer from './components/Layouts/Footer.jsx';
import BlogEditor from './pages/CreateBlogs/Blog.jsx';


import PaymentStatusContainer from './pages/Payment/payment_status/PaymentStatusContainer';
import OurHistory from './pages/AboutUs/Our History/OurHistory.jsx';
import BlogListing from './pages/BlogListing/BlogListing.jsx';
import BlogDetail from './pages/BlogDetail/BlogDetail.jsx';
import TermAndCondition from './pages/Policy/Term&Conditions.jsx';
import UserAgreement from './pages/Policy/UserAgreement.jsx';
import RefundPolicy from './pages/Policy/RefundPolicy.jsx';

const App = () => {
  return (
    <>

      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/our-activities" element={<OurActivities />} />
          <Route exact path="/our-mission" element={<OurMission />} />
          {/* <Route exact path="/about-us" element={<AboutUs />} /> */}
          <Route exact path="/about-us/vision" element={<AboutUs />} />
          <Route exact path="/about-us/history" element={<OurHistory/>} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/more" element={<More />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/payment/payment_form" element={<Payment_Form />} />
          <Route exact path="/blog/create" element={<BlogEditor />} />
          <Route exact path="/payment/payment_status" element={<PaymentStatusContainer />} />
          <Route exact path="/payment/payment_recipt" element={<PaymentReciptContainer />} />
          <Route exact path="/blogs" element={<BlogListing/>} />
          <Route path="/blog/:id/" element={<BlogDetail/>} />
          <Route path="/policies/tnc" element={<TermAndCondition/>}  />
          <Route path="/policies/user-agreement" element={<UserAgreement/>}  />
          <Route path="/policies/refund-policy" element={<RefundPolicy/>}  />
        </Routes>
        <Footer />
      </Router>

    </>

  );
}

export default App;

