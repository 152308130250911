import React from 'react'
import RecognitionCard from './cards/RecognitionCard'
import { ACCEDITATIONS } from '../constants/Acceditations'
function Recognization() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-10">
                        <div className="recognization-title-container">
                            <h1 className='recognization-title'>
                                Accreditations & Recognitions
                            </h1>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <a href="https://google.com" className='text-success text-decoration-none'>See More </a>
                    </div>
                </div>
                <div className="row">
                    {ACCEDITATIONS.map((properties, index) => (
                        <div className="col-md-2" key={index}>
                            <RecognitionCard compImg={properties.image} compImgAlt={properties.alt} description={properties.description} awardName={properties.award} />
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Recognization