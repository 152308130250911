import React from 'react'
import { assets } from '../assets/asset'

function NewsLetter() {
    return (
        <div className='d-flex flex-column gs__newsletter'>
            <div className="form-container">
                <form action="">
                    <div className="form-title">
                        <h1 className='title'>
                            Newsletter
                        </h1>
                    </div>
                    <div className="form-description">
                        <p className="description">
                            Get blog articles and offers via email
                        </p>
                    </div>
                    <div className="input-container">
                    <input type="email" name="newsLetterEmail" id="newsLetterEmail" placeholder='Your Email' className='newsletter-input' />
                        <img src={assets.icons.mailIcon} alt="mail" />
                    </div>
                    
                    <div className="btn-container w-100 mt-2">
                        <button type="submit" className='btn btn-subscribe'>Subscribe</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewsLetter