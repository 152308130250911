import cowIcon from '../assets/icons/gaumata-icon.png';
import hospitalIcon from '../assets/icons/hospital-icon.png';
import earthIcon from '../assets/icons/environment-icon.png';
import ayurvedaIcon from '../assets/icons/ayurveda-icon.png';
import flagIcon from '../assets/icons/sanatan-icon.png'

export const ABOUT_US_CARDS = [
    {
        title: 'Cow Protection (Gau Raksha)',
        icon: cowIcon,
        content: 'The Shree Geeta Gaushala is primarily where cow protection (Gau Raksha / Gau Seva) takes place. In Hinduism, Cow is seen as a divine animal associated with Lord Krishna. The idea of Gau Raksha is rooted in our culture and religion. Taking good care of cows also cultivates humanitarian and religious duty. Even the ‘go-sevaks’ have dedicated their life’s goal by serving and devotion to the mother cow.'
    },
    {
        title: 'The Kamdhenu Hospital',
        icon: hospitalIcon,
        content: 'Geeta Gaushala has come up with the agenda of building a multi-floored hospital for cows which will provide food, water, and other accommodations. Kamdhenu is a special hospital for cows and calves, can shelter more than 400 bovines. We also provide the Shree Panchagavya Ayurveda Chikitsalaya (A place of Ayurveda which heals through Panchagavya, typically it’s the Panchagavya, five products of the cow such as milk, ghee, curd, urine, and dung, etc.).'
    },
    {
        title: 'Environment Protection',
        icon: earthIcon,
        content: 'We mainly intend to save the environment through cow rearing and replacing inorganic fertilizer usage. Geeta Gaushala aims to become a renewable society. Cow’s feces are used in the compost that can be applied to plants instead of biogas. Majority aim to save the Desi Cow breeds and protect them from extinction. We promote the daily consumption of dairy products.'
    },
    {
        title: 'Spreading Ayurveda',
        icon: ayurvedaIcon,
        content: 'Another enlightening agenda is to spread the teachings of Ayurveda throughout the whole nation. Ayurveda is the most reliable and widely used overall healthcare system. In India, Ayurveda is deeply rooted in making us using traditional ways of making medicines. Ayurveda is also seen as a symbol of our heritage that helps spread awareness.'
    },
    {
        title: 'Sanatan Sanskar',
        icon: flagIcon,
        content: 'The Geeta Ashram Gaushala aims to install the traditional values and practices of Sanatan Sanskrita through the devotional practice of Gau seva (Cow Protection). In this way, we intend to serve and protect cows. We can also inspire others. We treat each cow with proper kindness and compassion. The main motive is to make this world and our country a better place for everyone.'
    }
];

export const ABOUT_US_FAQS = [
    {
        question: "What is the significance of Gau Raksha in Hinduism?",
        answer: "In Hinduism, the concept of gau raksha holds ultimate religious and cultural importance. Cows are considered a holy animal & a symbol of motherhood that needs to be protected."
    },
    {
        question: "What is the Motive of Geeta Ashram’s Gau Raksha Drive?",
        answer: "The primary motive of Geeta Ashram’s Gau Raksha Drive is to build the values of Sanatan dharma, spread the knowledge of Bhagavad Geeta, and make our country a world guru through the practices of cow protection."
    },
    {
        question: "What Activities are involved in Geeta Gaushala’s Cow Protection Drive?",
        answer: "In the Geeta Gaushala’s cow protection drive, there are other such activities also including cow awareness campaigns, providing medical care, and adoption of cows from our gaushala."
    },
    {
        question: "What is the need to protect cow breeds in gaushalas?",
        answer: "Cow protection shelters like Geeta Gaushala provide a haven for all cow breeds. It helps to focus on cow breed purities, care, and ultimate protection from slaughterhouses."
    }, {
        question: "What are the major impacts of Gau Raksha on our society?",
        answer: "Gau Raksha helps to build a religious connection in our society. It promotes cow welfare, focuses on non-violent methods, and also gives individuals another way of livelihood and economic stability."
    }
];