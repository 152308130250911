import React, { useState } from 'react';

const TextWithViewMore = ({ text, limit }) => {
  // State to keep track if the full text is shown
  const [isExpanded, setIsExpanded] = useState(false);

  // Function to toggle between truncated and full text
  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  // Check if text length is greater than the limit
  const isTextTooLong = text.length > limit;

  // Truncate the text if not expanded
  const displayText = isExpanded ? text : text.slice(0, limit) + (isTextTooLong ? '...' : '');

  return (
    <div>
      <p>{displayText}</p>
      {isTextTooLong && (
        <button onClick={toggleExpanded} className='view-more-btn' >
          {isExpanded ? 'View Less' : 'View More'}
        </button>
      )}
    </div>
  );
};

// Example usage
// const App = () => {
//   const exampleText = "Geeta Gaushala’s CSR initiative of homeschooling is the biggest step in favor of promoting various educational programs. The ‘SRIMANTA SHANKARDEV VIDYAPITH’ was established under SHISHU SHIKSHA SAMITI in ASSAM. This helps society get to know about its educational progress and provides support to the underprivileged.";

//   return (
//     <div>
//       <TextWithViewMore text={exampleText} limit={150} />
//     </div>
//   );
// };

 export default TextWithViewMore;
