import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import './BlogDetail.css'
import axios from 'axios'; 
import cowImg from '../../assets/Banner/cow-stand-street.jpg'
import { assets } from "../../assets/asset";
import { NavLink } from "react-router-dom";
import BlogsContainer from "../../components/BlogsContainer";
import { baseDomianURL } from "../../constants/config";
import NotFound from "../../components/NotFound/NotFound";

const BlogDetail = () => {
  const { id } = useParams();
  const [error, setError] = useState(false);
  // State to store the blog data
  const [blogData, setBlogData] = useState(null);
  // Fetch blog data from the API
  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(baseDomianURL + '/api/blogs/' + id); // Replace with your API endpoint
        setBlogData(response.data);
      } catch (error) {
        setError(true);
        console.error('Error fetching the blog data:', error);
      }
    };

    fetchBlogData();
  }, [id]);

  // Convert JSON to HTML string
  function jsonToHtml(json) {
    function elementToHtml(el) {
      const tag = el.tag;
      const attributes = [];

      if (el.class) {
        attributes.push(`class="${el.class}"`);
      }
      if (tag === 'a' && el.href) {
        attributes.push(`href="${el.href}"`);
      }
      if (tag === 'img' && el.src) {
        attributes.push(`class="blog_content_img"`)
        attributes.push(`src="${el.src}"`);
      }

      const attrString = attributes.length > 0 ? ` ${attributes.join(' ')}` : '';
      const content = el.children
        ? el.children.map(child => elementToHtml(child)).join('')
        : (el.content || '');

      return tag === 'img' || tag === 'br' 
        ? `<${tag}${attrString} />` 
        : `<${tag}${attrString}>${content}</${tag}>`;
    }

    return json.elements.map(el => elementToHtml(el)).join('');
  }

  if (!blogData) {
    return (
      <div className="blog-details-container container-fluid">
        <div className="row">
          <div className="main-blog-container col-12">
            <h2>Blog Not Found</h2>
            <p>The blog you are looking for does not exist or has been removed.</p>
          </div>
          <div className="recent-blogs-container col-12">
            <BlogsContainer title={"Recent Blogs"} />
          </div>
        </div>
      </div>
    );
  }

  // Convert blog content (JSON string) to HTML
  const htmlContent = jsonToHtml(JSON.parse(blogData.content)); // Assuming 'content' field contains the JSON

  return (
    <div className="blog-details-container container-fluid">
      <div className="row">
      <div className="main-blog-container col-12">
      <div className="blog-image-container">
        {/* <img src={blogData.imageUrl} alt={blogData.title} className="blog-image" /> */}
      </div>
      <div className="blog-content-container">
        <h1 className="blog-title">{blogData.title}</h1>
        <div className="blog-meta">
         <span className="blog-author">By {blogData.author}</span>
          {/*  <span className="blog-category">{blogData.category}</span> |  */}
          {/* <span className="blog-time-to-read">{blogData.createdAt}</span> |  */}
          <span className="blog-date"><img src={assets.icons.calenderDateMonthIcon} alt="date" width={20} height={20} /> updated on {new Date(blogData.updatedAt).toLocaleDateString()} {new Date(blogData.updatedAt).toLocaleTimeString()}</span>
        </div>
        {/* Inject the converted HTML here */}
        <div className="blog-description" dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </div>
      </div>
      <div className="recent-blogs-container col-12">
        {/* <h5 className="recent-post-list-title">Recent Blogs</h5> */}
        <BlogsContainer title={"Recent Blogs"}/>
      </div>
      </div>
      
    </div>
  );
};

export default BlogDetail;
