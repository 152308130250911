import { assets } from "../assets/asset";

export const OUR_ACTIVITIES_CARDS=[
    {
        title:"Cow Protection (Gau Raksha)",
        description:"Such rotational day-to-day activities of Geeta Gaushala such as gau seva and gau raksha ambulance help sustain our cow sanctuary's core ethos and objective. You can contribute to such a cooperative social deed by your contribution towards gaushala donation and raise funds as much as you can.",
        image:assets.cardsImg.cowGroupImg
    },
    {
        title:"Geeta Gyan",
        description:"The recitation of Geeta Gyan plays a huge role in propagating a positive impact on the whole society & promoting such social activities. This eventually helps to strengthen our mission of cow protection, cow care, and nurturing of cow breeds as a whole.",
        image:assets.cardsImg.geetaImg
    },
    {
        title:"Homeschool",
        description:"Geeta Gaushala’s CSR initiative of homeschooling is the biggest step in favor of promoting various educational programs. The ‘SRIMANTA SHANKARDEV VIDYAPITH’ was established under SHISHU SHIKSHA SAMITI in ASSAM. This helps society get to know about our culture & values. There are a lot of volunteers who take an active part in such cooperative activities.",
        image:assets.cardsImg.childImg
    },
    {
        title:"Food donation",
        description:"Under the food donation CSR activity, there is organic production of dairy products is included on priority basis. The effective management of the food donation drive can majorly contribute to different sustainability programs.",
        image:assets.cardsImg.foodDonationImg
    },
    {
        title:"Environment protection",
        description:"With Geeta Gaushala’s eco-friendly and sustainability programs, a lot of people are volunteering to protect the environment. The ultimate water treatment, composting, and production of biogas are the major steps taken.",
        image:assets.cardsImg.environmentProtectionImg
    },
    {
        title:"Organic Fertilizer",
        description:"There are multiple ways for the production of organic fertilizers made up of cow dung in Geeta Gaushala. This helps to boost the soil’s health and overall sustainability. Additionally, it reduces chemical dependency as a whole.",
        image:assets.cardsImg.organicFilterImg
    },
    {
        title:"Panchgvya medicine",
        description:"In the Geeta Gaushala, the production of panchgvya medicine opens the option of natural healthcare with a bare minimum of affordability. You can explore the potential benefits of Ayurvedic medicines.",
        image:assets.cardsImg.medicineImg
    },
    {
        title:"Yoga",
        description:"Yoga shares a common agenda for enhancing the common good and well-being of all citizens. Furthermore, it helps in building health awareness, reducing stress, and boosting mindfulness and social responsibility itself.",
        image:assets.cardsImg.yogaImg
    }
]