import React from 'react'
import ThreeDivLine from '../ThreeDivLine'

function VideoBanner() {
    return (
        <section className="gs__video pt-3 py-md-5">


            <div className='container gs__video-banner'>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-12">
                        <div className="row video-content-parent" >
                            <div className="col-12 col-md-6 video-container">
                                <iframe
                                    className="video-element"
                                    width="100%"
                                    height="315"
                                    src="https://www.youtube.com/embed/afHJYMbNGIA"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                            <div className="col-12 col-md-6 video-content">
                                <div className="content-container">
                                    <div className="content-heaining-container">
                                        <div className="content-heading">
                                            <h2 className='heading'>Daily Life At Shree Geeta Gaushala</h2>
                                        </div>
                                        <div className="line-container">
                                            <ThreeDivLine backgroundColor='#34AD27A3' />
                                        </div>
                                    </div>
                                    <div className="content-title-container">
                                        <p className='content-title'>Welcome to the Geeta Ashram!</p>
                                    </div>
                                    <div className="content-description-container">
                                        <p className='content-description'>
                                            In this section, we take you right through day to day rotation of our gaushala proceedings with valuable insights given by Shri Hanuman Prasad Baheti. The everyday morning and evening ritual of the ashram is where agnihotra prayers or performing aarti takes place. It also includes cleaning cows, milking cows, and providing them with water and clean areas around Gaushala.</p>
                                    </div>
                                    <div className="btn-container">
                                        <a href="#readmore">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VideoBanner