import React, { useState } from 'react';
import ThreeDivLine from '../../ThreeDivLine';
import ourRoleImg1 from '../../../assets/Banner/our-role-img-1.png';
import ourRoleImg2 from '../../../assets/Banner/our-role-img-2.png';
import ourRoleImg3 from '../../../assets/Banner/our-role-img-3.png';

const useAccordion = () => {
    const [activeIndex, setActiveIndex] = useState(1);

    const handleAccordionClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return { activeIndex, handleAccordionClick };
};

function OurRoles() {
    const { activeIndex, handleAccordionClick } = useAccordion();

    const accordionData = [
        {
            title: "Panchagavya Medicines",
            content: "The indiscriminate application of modern synthetic fertilizers or pesticides is destroying Mother Earth’s fertility and polluting the whole environment. A lot of efforts are being made to provide nectar-like organic food to the people by distributing/producing organic fertilizers and pesticides in the Ashram. We also offer sufficient training on the method of its manufacture."
        },
        {
            title: "Organic Fertilizers and Pesticides",
            content: "The indiscriminate application of modern synthetic fertilizers or pesticides is destroying Mother Earth’s fertility and polluting the whole environment. A lot of efforts are being made to provide nectar-like organic food to the people by distributing/producing organic fertilizers and pesticides in the Ashram. We also offer sufficient training on the method of its manufacture."
        },
        {
            title: "Geeta Gyan/Bhagavad Geeta Pathan",
            content: "The indiscriminate application of modern synthetic fertilizers or pesticides is destroying Mother Earth’s fertility and polluting the whole environment. A lot of efforts are being made to provide nectar-like organic food to the people by distributing/producing organic fertilizers and pesticides in the Ashram. We also offer sufficient training on the method of its manufacture."
        }
    ];

    return (
        <section className="roles-responsibilities py-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="main-heading-container">
                            <div className="heading-container d-inline-block">
                                <h1>Our Roles & Responsibilities</h1>
                                <div className="line-container">
                                    <ThreeDivLine backgroundColor='#34AD27A3' />
                                </div>
                            </div>
                        </div>
                        <p>
                            Our gaushala aims to make a healthy and self-sufficient India through this mission. You can use the daily items and medicines made from Gomata (cow) products. Using cow dung and cow urine helps create good fertilizers and pesticides. Additionally, the Panchgavya (a mixture of five cow products that are used in Ayurveda and traditional Indian clinical practices) and other cow products help us in multiple ways.
                        </p>
                        <div className="accordion" id="accordionExample">
                            {accordionData.map((item, index) => (
                                <div className='accordian-item' key={index}>
                                    <h2 className="accordion-header">
                                        <button
                                            className={`accordion-button ${activeIndex === index ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => handleAccordionClick(index)}
                                        >
                                            {item.title}
                                        </button>
                                    </h2>
                                    <div
                                        className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
                                    >
                                        <div className="accordion-content">
                                            {item.content}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-lg-6 background-color-back d-flex align-items-center">
                        <div className="row">
                            <div className="col-12 mb-3">
                                <img src={ourRoleImg1} className="img-fluid rounded" alt="Image 1" />
                            </div>
                            <div className="col-6">
                                <img src={ourRoleImg2} className="img-fluid rounded mb-3" alt="Image 2" />
                            </div>
                            <div className="col-6">
                                <img src={ourRoleImg3} className="img-fluid rounded mb-3" alt="Image 3" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OurRoles;
